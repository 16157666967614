import React from "react";
import { AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';

/**
 * Component to show basic horizontal bar chart.
 * 
 * @param {object} style - style properties to be passed to resulting svg.
 * @param {array} data - input data, in a form of array of two-element arrays.
 *      First element is the label, second element is the value.
 * @param {number} width - svg width.
 * @param {number} height - svg height.
 * @param {object} margin - Margin (inner padding from svg perspective).
 * @param {array} xDomain - Domain of x axis in a form of [min, max] numerical
 *      values.
 * @param {array} colRange - Range of colors.
 * @param {number} fontSize - font size used inside the visualization.
 * @param {boolean} hidden - Whether the component should be shown or not.
 */
export const HorizontalBarChart = ({
    style,
    data,
    width,
    height,
    margin = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    xDomain,
    colRange,
    fontSize = 9,
    hidden = false
}) => {
    if(hidden) { return <div></div> }

    if (!Array.isArray(data)) {
        return <div>Not an array data</div>
    }

    const leftAxisMargin = 20;
    const rightTextMargin = 20;

    const yScale = scaleBand()
        .domain(data.map(d => d[0]))
        .range([0, height - margin.top - margin.bottom])
        .padding(0.2);
    
    const xScale = scaleLinear()
        .domain(xDomain ? xDomain : [0, Math.max(...data.map(x => x[1]))])
        .range([0, width - margin.left - margin.right - leftAxisMargin - rightTextMargin]);
    
    const colScale = scaleOrdinal()
        .domain(data.map(d => d[0]))
        .range(colRange ? colRange : ['#ecf4f3']);

    return <svg width={width} height={height} style={style}>
        <rect x={0} y={0} width={width} height={height} fill={'#ecf4f3'} rx={2}/>
        <Group top={margin.top} left={margin.left + leftAxisMargin}>
            {data.map((d, i) => (
                <>
                    <rect
                        key={`bar-${i}`}
                        x={0}
                        y={yScale(d[0])}
                        width={xScale(d[1])}
                        height={yScale.bandwidth()}
                        fill={colScale(d[0])}
                    />
                    <text
                        key={`text-${i}`}
                        x={xScale(d[1]) + 3}
                        y={yScale(d[0]) + yScale.bandwidth() / 2}
                        fill={'#333333'}
                        fontSize={fontSize}
                        alignmentBaseline='middle'
                    >
                        {d[1]}
                    </text>
                </>
                
            ))}
        </Group>
        <AxisLeft
            top={margin.top}
            left={margin.left + leftAxisMargin}
            scale={yScale}
            tickLength={3}
            tickLineProps={{
                stroke: '#333333',
                strokeWidth: 1
            }}
            tickLabelProps={{
                fill: '#333333',
                fontSize: fontSize
            }}
            hideAxisLine
        />

    </svg>
}