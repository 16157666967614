import React, { useEffect, useState } from "react";
import { dynoapeAPI, getSortedPositions, getSortedCompetences } from "../api/dynoape";
import { useParams, useHistory } from "react-router-dom";
import { Header, Task } from "../components";
import { Button, Modal, Spinner } from "rendition";
import { BackButton } from "../components/buttons";
import Icon from "../lib/icon";
import Tooltip from "../lib/tooltip";
import { isTaskStatusReadOnly, validCharactersRegex } from "../lib/common";
import {SolidInput} from "../lib/styled-components";

const TaskView = () => {
  const [showTaskModal, setShowModal] = useState(false);
  const [showInvalidNameError, setShowInvalidNameError] = useState({ "show": false, "msg": "" });
  const [taskName, setNameInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [task, setTask] = useState({});
  const [taskStatus, setTaskStatus] = useState();
  const { departmentId, taskId } = useParams();
  const [department, setDepartment] = useState({});
  const [employees, setEmployees] = useState([]);
  const [positions, setPositions] = useState({});
  const [traitNames, setTraitNames] = useState({});
  const [result, setResult] = useState({});
  const history = useHistory();

  const closeEditNameModal = () => {
    setShowModal(false);
    setShowInvalidNameError({ "show": false, "msg": "" });
  }

  const updateTask = async () => {
    if (showInvalidNameError.show) {
      return;
    }
    const response = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}`, [{
      "op": "replace",
      "path": "/name",
      "value": taskName.trim()
    }]);
    if (response) {
      task.name = taskName;
      closeEditNameModal();
    }
  };

  const getData = async () => {
    const [
      departmentData,
      taskData,
      employeesData,
      positionsData,
      competencesData,
      resultData
    ] = await Promise.all([
      dynoapeAPI.get(`/api/v1/department/${departmentId}`),
      dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${taskId}?includeTaskEmployees=true
        &includeTaskShiftTypes=true&includeTaskTraits=true&includeTaskPositions=true
        &includeTaskHolidays=true`),
      dynoapeAPI.get(
          `/api/v1/department/${departmentId}/employees?includeBlockedPatterns=true
          &includeWeekendPatterns=true
          &includeVacationPatterns=true
          &includeHolidayPatterns=true
          &includePaidLeavePatterns=true`),
      getSortedPositions(departmentId),
      getSortedCompetences(departmentId),
      dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${taskId}/result/employees-scheduled-day-segments`)
    ]);

    setDepartment(departmentData);
    if(!isTaskStatusReadOnly(taskData.status)) {
      taskData.config.employees = employeesData.filter(e => e.enabled);
    }
    let emplNames = employeesData.reduce(
      (acc, empl) => {acc[empl.id] = empl.name; return acc;},
      {}
    );
    setEmployees(taskData.config.employees.map(empl => ({
      ...empl,
      name: emplNames[empl.id] || 'Ansatt slettet'
    })));
    setTask(taskData);
    if (taskData)
      setTaskStatus(taskData.status);
    setTraitNames(Object.fromEntries(
      competencesData.map(t => [t.id, t.name])
    ));
    setPositions(positionsData);
    setResult(resultData.elements);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading || department === undefined || task === undefined) {
    return (<div style={{ marginLeft: "120px", marginTop: "10px" }}>
      <Spinner />
      {!isLoading && (department === undefined || task === undefined) && (
        <Modal
          title="Avdeling"
          cancel={() => {

          }}
          cancelButtonProps={{
            style: { display: "none" }
          }}
          done={() => {
            history.push(`/avdelinger`);
          }}
          action="Gå tilbake"
        >
          <p>Denne bemanningsplanen tilhører en avdeling som ikke er delt med deg.</p> <p>Ta kontakt med eier av avdelingen eller <a href="mailto: support@dynamon.no">support@dynamon.no</a></p>
        </Modal>
      )}
    </div>
    )
  }

  return (
    <div>
      <Header title={task.name} showLicenseGenerationsInfo={true}
        withTooltip
        tooltipId="task-header"
        tooltipContent="Her gjør du overordnede innstillinger iht. lokale avtaler mellom arbeidsgiver- og arbeidstakerorganisasjoner">
        {!isTaskStatusReadOnly(taskStatus) && <Button
          plain
          icon={<Icon name="edit" stroke="#26565B" />}
          onClick={() => {
            setNameInput(task.name);
            setShowModal(true);
          }}
          data-for="edit-name"
          data-tip="Navnet du har gitt Bemanningsplanen. Dette kan du endre ved å klikke på blyanten og skrive inn nytt navn på denne Bemanningsplanen"
        >
        </Button>}
        <Tooltip id="edit-name" />
        <BackButton />
      </Header>
      <div className="content">
        <Task
          department={department}
          task={task}
          taskStatus={taskStatus}
          setTaskStatus={setTaskStatus}
          employees={employees}
          traitNames={traitNames}
          positions={positions}
          result={result}
        />
      </div>
      {showTaskModal && (
        <Modal
          title="Bemanningsplan"
          cancel={() => {
            closeEditNameModal();
          }}
          done={() => {
            updateTask();
          }}
          action="Lagre"
          cancelButtonProps={{
            children: 'Avbryt'
          }}
        >
          <SolidInput
            onChange={(e) => {
              let name = e.target.value;
              setNameInput(name);

              if (name.trim().length <= 0) {
                setShowInvalidNameError({ "show": true, "msg": "Et navn er påkrevd, skriv inn et navn på bemanningsplanen" });
                return;
              }

              if (name.length > 40) {
                setShowInvalidNameError({ "show": true, "msg": "Navn på bemanningsplan kan ikke være lenger enn 40 tegn" });
                return;
              }

              if (!validCharactersRegex().test(name)) {
                setShowInvalidNameError({ "show": true, "msg": "Navn på bemanningsplan kan ikke inneholde ugyldige tegn" });
                return;
              }
              setShowInvalidNameError({ "show": false, "msg": "" });

            }}
            placeholder="Navn"
            defaultValue={task.name}
          />
          {showInvalidNameError.show && (
            <p style={{ color: "#BF3D2B" }}>{showInvalidNameError.msg}</p>
          )}
        </Modal>
      )}
    </div>
  )
};

export default TaskView;
