import React, {useState} from "react";
import {Button, Flex} from "rendition";
import { DayShift } from "./day-shift";
import styled from "styled-components";
import Tooltip from "../lib/tooltip";
import Select from "react-select";
import {
    emptyDemand, hoursToLocalTime,
    isTaskReadOnly, localTimeToHours, norwegianShiftNames,
    shiftTypeLimitInOrdinaryBp,
    sortShiftCodesByDaySegment
} from "../lib/common";
import {ShiftStats} from "./shift-stats";

export const DayInput = ({
  register,
  unregister,
  department,
  task,
  employees,
  day,
  errors,
  positions,
  traitNames,
  watch,
  patchTask,
  doPatch,
  setValue,
  switchToDaySegmentView,
  changeShiftViewButton
}) => {
  const [selectedShift, setShift] = useState(null);
  const [selectedDaySegment, setSelectedDaySegment] = useState(null);
  const [showStatsPage, setShowStatsPage] = useState(task.status !== "FROZEN");
  const shiftButtons = sortShiftCodesByDaySegment(Object.keys(task.config.weeklyCoverDemands[day]), Object.values(task.config.shiftTypes))?.map(shift =>
    <div key={shift} style={{float: "left", paddingBottom: "15px"}}>
      <Button primary={selectedShift === shift} data-for={shift} data-tip="Her ser du hvilken vakt du skal spesifisere/står i. Dersom du ikke har f.eks. “Langvakt/Mellomvakt”, så settes det null som tall i minimum- og maksimum antall ansatte"
              onClick={() => {setShift(shift); setShowStatsPage(false);}}>{shift}</Button>
      <Tooltip id={shift} />
    </div>
  );

  const daySegmentButtons = ["D", "A", "N", "L"].map(ds =>
      <div key={ds} style={{float: "left", paddingBottom: "15px"}}>
        <Button primary={selectedDaySegment === ds} data-for={ds} data-tip="Her kan du velge vaktkategori"
                onClick={() => {setSelectedDaySegment(ds); setShowStatsPage(false); setShift(undefined)}}>
          {`${norwegianShiftNames(ds)} (${Object.keys(task.config.weeklyCoverDemands[day]).filter(code => task.config.shiftTypes[code].daySegment === ds).length})`}
        </Button>
        <Tooltip id={ds} />
      </div>
  )

  return (
    <div>
        <DayCard>
          <div style={{maxWidth: "690px", minWidth: "690px"}}>
            {switchToDaySegmentView === true ? daySegmentButtons : shiftButtons}
          </div>
          {changeShiftViewButton}
          {task.status !== "FROZEN" && <div style={{marginLeft: "30px"}}><Button primary={showStatsPage} success={!showStatsPage} onClick={() => {
            setShowStatsPage(!showStatsPage);
            setShift(null);
          }}>{!showStatsPage ? "Vis" : "Skjul"} sammendrag</Button></div>}
          {!isTaskReadOnly(task) && Object.keys(task.config.weeklyCoverDemands[day]).length < shiftTypeLimitInOrdinaryBp() && <div style={{marginLeft: "20px"}}>
                <Select
                    placeholder={`Legg til vaktkode (maks. ${shiftTypeLimitInOrdinaryBp()})`}
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    controlShouldRenderValue={false}
                    options={sortShiftCodesByDaySegment(Object.keys(task.config.shiftTypes), Object.values(task.config.shiftTypes))
                        ?.filter(sh => !Object.keys(task.config.weeklyCoverDemands[day]).includes(sh))
                        ?.filter(sh => !selectedDaySegment || task.config.shiftTypes[sh].daySegment === selectedDaySegment)
                        ?.map(sh => ({label: <Flex flexDirection={"row"}>
                                <div style={{marginRight: "5px", width: "55px"}}><b>{sh}</b></div>
                                <div style={{marginRight: "25px"}}>{task.config.shiftTypes[sh].start}</div>
                                <div>{hoursToLocalTime(localTimeToHours(task.config.shiftTypes[sh].start) + task.config.shiftTypes[sh].shiftHoursMax)}</div>
                        </Flex>, value: sh}))}
                    onChange={(e) => {
                        let baseSh = task.config.shiftTypes[e.value].daySegment;
                        doPatch([{
                            op: "add",
                            path: `/config/weeklyCoverDemands/${day}/${e.value}`,
                            value: emptyDemand(baseSh)
                        }], "Vaktkode lagt til");
                        setTimeout(() => {
                        }, 1000)
                      if(!switchToDaySegmentView) { setShift(e.value) } else { setSelectedDaySegment(task.config.shiftTypes[e.value].daySegment); }
                      setShowStatsPage(false)
                    }}
                    styles={{ control: base => ({ ...base, borderWidth: "3px", borderColor: "#222"}), placeholder: base => ({...base, color: "#222"})}}
                >
                </Select>
            </div>}
        </DayCard>

        {selectedShift && !switchToDaySegmentView && Object.keys(task.config.weeklyCoverDemands[day]).includes(selectedShift) &&
            <DayShift register={register} unregister={unregister} task={task} day={day}
                      shift={selectedShift} errors={errors} positions={positions} traitNames={traitNames} watch={watch}
                      patchTask={patchTask} doPatch={doPatch} setShift={setShift} setValue={setValue} department={department}
            />}
      {showStatsPage && <ShiftStats task={task} employees={employees}></ShiftStats>}
      {switchToDaySegmentView && selectedDaySegment && Object.keys(task.config.weeklyCoverDemands[day])
          .filter(code => task.config.shiftTypes[code].daySegment === selectedDaySegment)
          .map(code =>
              (<DayShift key={code} register={register} unregister={unregister} task={task} day={day}
                         shift={code} errors={errors} positions={positions} traitNames={traitNames} watch={watch}
                         patchTask={patchTask} doPatch={doPatch} setShift={setShift} setValue={setValue} department={department}
              />)
          )}
    </div>
  );
};

const DayCard = styled.div`
  display: flex;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 25px;
  padding: 25px;
  button {
    margin-right: 10px;
  }
  border-top-left-radius: 0px;
  width: 1350px;
`;