
import React, { useEffect, useState } from "react";
import {Alert, Modal, Spinner} from "rendition";
import {dynoapeAPI} from "../api/dynoape";
import { useParams, NavLink } from "react-router-dom";
import { AvatarCardEmployeePosition } from "../lib/styled-components";
import { Button } from "rendition";
import { Table } from "rendition";
import { Txt } from "rendition";
import { formatStringDateTime } from "../lib/common";
import Tooltip from "../lib/tooltip";
import uniqolor from 'uniqolor';
import styled from "styled-components";
import Icon from "../lib/icon";
import {FeatureToggles} from "../lib/feature-toggles";

const EmployeeTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [refresh ,setRefresh] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [showConfirmDeleteEmployee, setShowConfirmDeleteEmployee] = useState({show: false, id: null});
  const [manYears, setManYears] = useState({
    total: 0,
    totalAvailable: 0,
    average: 0,
    averageAvailable: 0
  });
  const { departmentId } = useParams();

  const columns = [
    {
      field: 'name',
      label: <span
        data-for="employee-name"
        data-tip="Navn eller ansattnummer for dine ansatte (ikke bruk for- og etternavn)"
      >
        <Tooltip id="employee-name" />Navn</span>,
      render: function displayColumn(data) {
        return <span style={{ width: "150px", overflowWrap: "break-word", display: "block" }}>{data}</span>
      },
      sortable: true
    },
    {
      field: 'restPosition',
      label: <span
        data-for="employee-position"
        data-tip="Hvilken stilling den ansatte har i avdelingen"
      >
        <Tooltip id="employee-position" />Stilling</span>,
      render: function displayColumn(data) {
        if (!data) {
          return (<p>Mangler stilling</p>)
        }
        return (
          <AvatarCardEmployeePosition style={{ backgroundColor: data.color !== "000000" ? `#${data.color}` : uniqolor(data.id, { format: 'rgb' }).color, color: "white" }}>
            {data.name}
          </AvatarCardEmployeePosition>
        )
      },
      sortable: function sort(a, b) {
        if (!a.restPosition && !b.restPosition)
          return 0;

        if (!a.restPosition)
          return 1;

        if (!b.restPosition)
          return -1;

        return a.restPosition.name.localeCompare(b.restPosition.name);
      }
    },
    {
      field: 'registered',
      label: 'Registrert',
      render: data => <Txt style={{ whiteSpace: "nowrap" }}>{formatStringDateTime(data)}</Txt>,
      sortable: true
    },
    {
      field: 'updated',
      label: 'Sist oppdatert',
      render: data => <Txt style={{ whiteSpace: "nowrap" }}>{formatStringDateTime(data)}</Txt>,
      sortable: true
    },
    {
      field: 'id',
      label: ' ',
      render: function displayColumn(data) {
        return <NavLink className="nav-link" to={`/avdelinger/${departmentId}/ansatte/${data}`}
          data-for={`employee-${departmentId}`}
          data-tip="Se/rediger spesifikasjoner for den enkelte ansatte"
        ><Tooltip id={`employee-${departmentId}`} /><Button primary>Se/rediger</Button></NavLink>;
      }
    },
    {
      field: 'orgEmployeeId',
      label: ' ',
      render: function displayColumn(data) {
        return data && FeatureToggles.FEATURE_TOGGLE_ORG_EMPLOYEE_MODULE.isEnabled()
            ? <Icon name="three-people"/>
            : <></>;
      }
    },
    {
      field: 'id',
      label: ' ',
      render: function displayColumn(data) {
        return <Button data-for={`employee-${departmentId}-delete`}
                       data-tip="Slett ansatt"
                       onClick={() => setShowConfirmDeleteEmployee({show: true, id: data})} danger>
          <Tooltip id={`employee-${departmentId}-delete`} />Slett</Button>;
      }
    }
  ];

  const deleteEmployee = async (id) => {
    setIsLoading(true)
    try {
      await dynoapeAPI.delete(`/api/v1/department/${departmentId}/employee/${id}`);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false)
    setRefresh(!refresh)
  }

  useEffect(() => {
    const getDepartmentEmployees = async () => {
      const employeesPromise = dynoapeAPI.get(`/api/v1/department/${departmentId}/employees`);
      const employeesResponse = await employeesPromise;

      const sortedEmployees = employeesResponse?.sort(function (a, b) {
        if(a.priority === b.priority) {
          return new Date(a.registered) - new Date(b.registered);
        } else {
          return a.priority - b.priority;
        }
      });

      const availableEmployees = employeesResponse?.filter(function (emp) {
        return emp.enabled;
      });

      const availableManYearSum = availableEmployees.reduce((acc, emp) => {
        return acc + emp.vacancyRate;
      }, 0);

      const manYearSum = employeesResponse.reduce((acc, emp) => {
        return acc + emp.vacancyRate;
      }, 0);

      setEmployees(sortedEmployees);
      setIsLoading(false);
      setManYears({
        total: (manYearSum / 100),
        totalAvailable: (availableManYearSum / 100),
        average: (manYearSum / employeesResponse.length),
        averageAvailable: (availableManYearSum / availableEmployees.length)
      });
    };

    getDepartmentEmployees();
  }, [refresh]);

  return (
    <div style={{ background: "white" }}>
      {isLoading ? <Spinner show /> : !employees.length ?
        <div><h4>Ingen ansatte funnet</h4></div>
        : <div>
          <ManYearTitle>
            <Tooltip id="manyear-available" />
            <span
              data-for="manyear-available"
              data-tip="Tilgjengelige årsverk viser antall årsverk som er aktive og som vil bli tatt med i generering av turnus"
            >Tilgjengelig årsverk: <b>{manYears.totalAvailable.toFixed(2)}</b></span>
            -
            <Tooltip id="manyear-avg" />
            <span
              data-for="manyear-avg"
              data-tip="Gjennomsnittlig stillingsprosent tilgjengelig for de ansatte som skal tas med i generering av turnus"
            >Gjennomsnittlig stillingsprosent tilgjengelig: <b>{manYears.averageAvailable.toFixed(2)}%</b></span>
          </ManYearTitle>
          <ManYearTitle>
            <Tooltip id="manyear-total" />
            <span
              data-for="manyear-total"
              data-tip="Totalt antall årsverk som er registrert i avdelingen"
            >Total årsverk: <b>{manYears.total.toFixed(2)}</b></span>
            -
            <Tooltip id="manyear-total-avg" />
            <span
              data-for="manyear-total-avg"
              data-tip="Gjennomsnittlig stillingsprosent for ALLE ansatte som er registrert i  avdelingen"
            >Gjennomsnittlig stillingsprosent: <b>{manYears.average.toFixed(2)}%</b></span>

          </ManYearTitle>
          <Table pagination={{itemsPerPage: 200}} columns={columns} data={employees} getRowClass={function displayRow(row) {
            if (!row.enabled)
              return ["disabled-row"];
          }} rowKey="id" />
        </div>
      }
      {showConfirmDeleteEmployee.show && (
          <Modal
              cancel={() => {
                setShowConfirmDeleteEmployee(false)
              }}
              done={() => {
                deleteEmployee(showConfirmDeleteEmployee.id);
                setShowConfirmDeleteEmployee({show: false, id: null});
              }}
              action="OK"
              cancelButtonProps={{
                children: 'Avbryt'
              }}
          >
            <h3><Alert plaintext danger style={{ whiteSpace: "nowrap" }}>Vil du slette?</Alert></h3><p>Er du sikker på at du vil slette den ansatte?</p>
          </Modal>
      )}
    </div>
  );
};

const ManYearTitle = styled.span`
  position: relative;
  font-size: 16px;
  display: block;
  padding-bottom: 10px;
  z-index: 1;
  width: 700px;
`;

export default EmployeeTable;
