import React, {useEffect, useState} from "react";
import {dynoapeAPI, rawDynoapeAPI} from "../api/dynoape";
import {Button, Checkbox, Flex, Input, Modal, Spinner, Table} from "rendition";
import styled from "styled-components";
import Icon from "../lib/icon";
import Tooltip from "../lib/tooltip";

export const DepartmentSharing = ({
    departmentId
                                  }) => {

    const [sharedInfo, setSharedInfo] = useState(undefined);
    const [sharedInfoWithEmails, setSharedInfoWithEmails] = useState(false);
    const [loadError, setLoadError] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [sharingInput, setSharingInput] = useState({email: undefined, permissions: ["DEPARTMENT_READ"]});
    const [showShareModal, setShowShareModal] = useState({show: false, id: undefined});
    const [showDeleteModal, setShowDeleteModal] = useState({show: false, id: undefined, email: undefined});

    const resetToDefault = () => {
        setSharingInput({email: undefined, permissions: ["DEPARTMENT_READ"]});
        setShowShareModal({show: false, id: undefined});
        setShowDeleteModal({show: false, id: undefined, email: undefined});
    }

    const getData = async (includeEmail) => {
        setIsLoading(true);
        const response = await rawDynoapeAPI.get(`api/v1/department/${departmentId}/shared-info?includeUserEmail=${includeEmail}`);
        if(response.status === 200) {
            setSharedInfo(response.data.body)
        } else {
            setLoadError(true)
        }
        setIsLoading(false)
        setSharedInfoWithEmails(includeEmail);
    }

    useEffect(() => {
        getData(false);
    }, []);

    const permissions = {
        DEPARTMENT_READ: "Lesetilgang",
        DEPARTMENT_UPDATE: "Skrivetilgang",
        DEPARTMENT_OWNER: "Eiertilgang"
    }

    const columns = [
        {
            field: 'email',
            label: <span style={{ width: "400px", display: "block" }}><Info>E-post</Info></span>,
            sortable: false,
            render: function displayColumn(data, obj) {
                return <span><Info>{obj.userDeleted ? <i>Bruker slettet</i> : data}</Info></span>
            }
        },
        {
            field: 'permissions',
            label: <span style={{ width: "500px", display: "block" }}><Info>Tilganger</Info></span>,
            sortable: false,
            render: function displayColumn(data) {
                return <span><Info>{data.map(p => permissions[p]).join(", ")}</Info></span>
            }
        },
        {
            field: 'id',
            label: <span style={{ width: "5px", display: "block" }}></span>,
            render: function displayColumn(data, obj) {
                return <>
                    <Button
                        id={`putSharing-${data}`}
                        data-for="putSharing"
                        data-tip="Trykk her hvis du ønsker å endre denne tilgangen"
                        primary
                        onClick={() => {
                            setSharingInput({email: obj.email, permissions: obj.permissions})
                            setShowShareModal({show: true, id: data})
                        }}
                        style={{ float: "right"}}>
                        <Icon name="edit-w"/>
                    </Button>
                    <Tooltip id="putSharing" />
                </>;
            }
        },
        {
            field: 'id',
            label: <span style={{ width: "5px", display: "block" }}></span>,
            render: function displayColumn(data, obj) {
                return <>
                    <Button
                        id={`deleteSharing-${data}`}
                        data-for="deleteSharing"
                        data-tip="Trykk her hvis du ønsker å fjerne tilgangen fra denne brukeren"
                        danger
                        onClick={() => setShowDeleteModal({ "show": true, "id": data, "email": obj.email })}
                        style={{ float: "right"}}>
                        <Icon name="trash-w"/>
                    </Button>
                    <Tooltip id="deleteSharing" />
                </>;
            }
        }
    ]

    if(isLoading) return (<Spinner show />)

    return (
        <div>
            <h3>Brukere med tilgang</h3>
            {loadError &&
                <BigInfo style={{color: "red"}}>Du har ikke tilgang til å se hvem denne avdelingen er delt med</BigInfo>
            }
            {sharedInfo !== undefined &&
                <div>
                    <Button
                        id="shareButton"
                        data-for="shareButton"
                        data-tip="Trykk på denne hvis du ønsker å gi en bruker tilgang til din avdeling"
                        onClick={() => setShowShareModal({show: true})}
                        style={{marginBottom: "10px"}}
                        primary
                    >
                        Del med bruker
                    </Button>
                    <Tooltip id="shareButton" />
                    <hr/>
                    <BigInfo>Denne avdelingen er delt med {sharedInfo.elements.length} bruker(e)</BigInfo>
                    <Button
                        data-tip="Trykk her hvis du ønsker å se hvilke brukere avdelingen din er delt med"
                        data-for="showUsers"
                        style={{marginBottom: "40px"}}
                        underline
                        disabled={sharedInfo.elements.length === 0}
                        primary
                        onClick={() => getData(true)}><BigInfo>Last inn brukere</BigInfo>
                    </Button>
                    <Tooltip id="showUsers" />
                    {sharedInfoWithEmails &&
                        <Table columns={columns} data={sharedInfo.elements} rowKey="id" />
                    }
                </div>
            }

            {showShareModal.show &&
                <Modal
                    title={`${showShareModal.id ? "Oppdater tilgang" : "Del avdeling med bruker"}`}
                    cancel={() => resetToDefault()}
                    done={async () => {
                        setIsLoading(true)
                        const body = {...sharingInput};
                        if(body.permissions.includes("DEPARTMENT_OWNER")) body.permissions = ["DEPARTMENT_OWNER"]
                        const resp = !showShareModal.id
                            ? await dynoapeAPI.post(`api/v1/department/${departmentId}/share`, body)
                            : await dynoapeAPI.put(`api/v1/department/${departmentId}/share/${showShareModal.id}`, {elements: body.permissions});
                        if(resp) {
                            await getData(true);
                        }

                        resetToDefault();
                        setIsLoading(false);
                    }}
                    action={`${showShareModal.id === undefined ? "Legg til" : "Oppdater"}`}
                    primaryButtonProps={{disabled: isLoading, "data-testid": "confirm-add"}}
                    cancelButtonProps={{
                        children: "Avbryt",
                    }}
                >
                    <div>
                        <p><label htmlFor={"emailInput"}>E-post</label></p>
                        <Input style={{width: "300px", borderColor: "hsl(0, 0%, 80%)", backgroundColor: `${showShareModal.id ? "lightgrey": ""}`}}
                               type="email"
                               data-for="emailInput"
                               data-tip="Skriv inn epost-adresse"
                               id={"emailInput"}
                               disabled={isLoading || showShareModal.id}
                               value={sharingInput.email}
                               onChange={(e) => setSharingInput({...sharingInput, email: (e.target.value + "").toLowerCase()})}
                        ></Input>
                        <Tooltip id="emailInput" />
                        <p style={{marginTop: "30px"}}><label htmlFor={"permissionInput"}>Velg tilganger</label></p>
                        <Flex mb={"30px"} id={""}>
                            <div
                                data-for="readPermissionInput"
                                data-tip="Klikk på denne for å velge lesetilgang">
                                <Checkbox
                                    checked={sharingInput.permissions.includes("DEPARTMENT_READ")}
                                    label={`${permissions['DEPARTMENT_READ']}`}
                                    onChange={() => setSharingInput(
                                        {...sharingInput,
                                            permissions:
                                                sharingInput.permissions.includes("DEPARTMENT_READ")
                                                    ? sharingInput.permissions.filter(p => p !== "DEPARTMENT_READ")
                                                    : [...sharingInput.permissions, "DEPARTMENT_READ"]}
                                    )
                                    }
                                />
                                <Tooltip id="readPermissionInput" />
                            </div>
                            <div
                                data-for="updatePermissionInput"
                                data-tip="Klikk på denne for å velge skrivetilgang">
                                <Checkbox
                                    ml={"20px"}
                                    checked={sharingInput.permissions.includes("DEPARTMENT_UPDATE")}
                                    label={`${permissions['DEPARTMENT_UPDATE']}`}
                                    onChange={() => setSharingInput(
                                        {...sharingInput,
                                            permissions:
                                                sharingInput.permissions.includes("DEPARTMENT_UPDATE")
                                                    ? sharingInput.permissions.filter(p => p !== "DEPARTMENT_UPDATE")
                                                    : [...sharingInput.permissions, "DEPARTMENT_UPDATE"]}
                                    )
                                    }
                                />
                                <Tooltip id="updatePermissionInput" />
                            </div>
                            <div
                                data-for="ownerPermissionInput"
                                data-tip="Klikk på denne for å velge eiertilgang">
                                <Checkbox
                                    ml={"20px"}
                                    checked={sharingInput.permissions.includes("DEPARTMENT_OWNER")}
                                    label={`${permissions['DEPARTMENT_OWNER']}`}
                                    onChange={() => setSharingInput(
                                        {...sharingInput,
                                            permissions:
                                                sharingInput.permissions.includes("DEPARTMENT_OWNER")
                                                    ? sharingInput.permissions.filter(p => p !== "DEPARTMENT_OWNER")
                                                    : ["DEPARTMENT_OWNER"]}
                                    )
                                    }
                                />
                                <Tooltip id="ownerPermissionInput" />
                            </div>
                        </Flex>
                    </div>
                    {sharingInput.permissions.includes("DEPARTMENT_OWNER") &&
                        <p style={{color: "red"}}><Info>
                            <b>NB!</b> Ved å velge eiertilgang, vil bruker få full lese -og skriverett til hele avdelingen.
                            Dette inkluderer også tilgang til epost-adressene til brukerne som denne avdelingen er delt med.
                        </Info></p>
                    }
                    <p><Info><b>Husk!</b> For at en bruker skal ha skrivetilgang, må også lesetilgang gis.</Info></p>
                </Modal>
            }
            {showDeleteModal.show && (
                <Modal
                    title="Fjern tilganger"
                    cancel={() => resetToDefault()}
                    done={async () => {
                        await dynoapeAPI.delete(`api/v1/department/${departmentId}/share/${showDeleteModal.id}`)
                        await getData(true)
                        resetToDefault()
                    }}
                    primaryButtonProps={{disabled: isLoading, "data-testid": 'confirm-delete'}}
                    action="Fjern tilganger"
                    cancelButtonProps={{
                        children: 'Avbryt'
                    }}
                >
                    <p><Info>Er du sikker på at du vil fjerne tilgangene til {showDeleteModal.email}?</Info></p>
                </Modal>
            )}
        </div>
    )
}

const BigInfo = styled.div`
  font-family: Montserrat Alternates;
  font-size: 18px;
`;

const Info = styled.div`
  font-family: Montserrat Alternates;
  font-size: 14px;
`;