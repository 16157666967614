import React, { useState, useEffect } from "react";
import { getErrorMessage } from "../lib/common";
import { Modal } from "rendition";
import axios from "axios";

const dynoape = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL
});

const rawDynoape = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL
})

const rawDynoapeAPI = {
    get: async (url) => {
        try {
            return await rawDynoape.get(url);
        } catch (err) {
            return err;
        }
    }
}

const getCsrf = async () => {
    try {
        const response = await dynoape.get(`${process.env.REACT_APP_SERVER_URL}/csrf`, { withCredentials: true }).catch(function (error) { console.error(error); return []; })
        dynoape.defaults.headers.common['X-XSRF-TOKEN'] = response.data.token;
        dynoape.defaults.withCredentials = true;
        return response.data.token;
    } catch (error) {
        console.log('Error fetching CSRF token:', error);
        return null;
    }
}

const dynoapeAPI = {
    get: async (url) => {
        const response = await dynoape.get(url).catch(function (error) { console.error(error); return []; });
        return response?.data?.body;
    },
    post: async (url, data) => {
        await getCsrf();
        const response = await dynoape.post(url, data).catch(function (error) { console.error(error); return []; });
        return response?.data?.body;
    },
    put: async (url, data) => {
        await getCsrf();
        const response = await dynoape.put(url, data).catch(function (error) { console.error(error); return []; });
        return response?.data?.body;
    },
    emPatch: async (url, data) => {
        await getCsrf();
        const response = await dynoape.patch(url, data).catch(function (error) { console.error(error); return []; });
        return response?.data?.body;
    },
    patch: async (url, data) => {
        await getCsrf();
        const response = await dynoape.patch(url, data, {
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        }).catch(function (error) { console.error(error); return []; });
        return response?.data?.body;
    },
    delete: async (url) => {
        await getCsrf();
        const response = await dynoape.delete(url);
        return response?.data?.body;
    }
};

const getSortedPositions = async (departmentId) => {
    const pos = await dynoapeAPI.get(`/api/v1/department/${departmentId}/positions`)
    if (!pos)
        return []
    return pos.sort(function (a, b) {
        return a.name.localeCompare(b.name, 'no', { numeric: true, sensitivity: 'base' });
    });
}

const getSortedCompetences = async (departmentId) => {
    const comp = await dynoapeAPI.get(`/api/v1/department/${departmentId}/traits`);
    if (!comp)
        return []
    return comp.sort(function (a, b) {
        return a.name.localeCompare(b.name, 'no', { numeric: true, sensitivity: 'base' });
    });
}

const getSortedShiftTypes = async (departmentId) => {
    const shiftTypes = await dynoapeAPI.get(`/api/v1/department/${departmentId}/shift-types`);
    if(! shiftTypes) {
        return []
    }
    return shiftTypes.sort(function (a, b) {
        return a.code.localeCompare(b.code, 'no', { numeric: true, sensitivity: 'base' });
    });
}

const addAccessTokenInterceptor = (getAccessTokenSilently) => {
    [dynoape, rawDynoape].forEach(api => {
        api.interceptors.request.use(async (config) => {
            const token = await getAccessTokenSilently();
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        });
    });
};

function ErrorHandler(props) {
    const [showErrorModal, setShowErrorModal] = useState({ display: false, message: "" });

    useEffect(() => {
        dynoape.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (getErrorMessage(error.response).length > 0)
                setShowErrorModal({ display: true, message: getErrorMessage(error.response) });
            return Promise.reject(error);
        });
    }, []);

    return <>
        {props.children}
        {showErrorModal.display && (
            <Modal
                title="Feil"
                done={() => {
                    setShowErrorModal({ display: false, message: "" });
                }}
                action="Ok"
            >
                <p>{showErrorModal.message}</p>
            </Modal>
        )}
    </>;
}

const dbPortalTimeDiffMinutes = process.env.REACT_APP_DB_PORTAL_TIME_DIFF_MINUTES || 0;
const taskTimeoutMinutes = process.env.REACT_APP_TASK_TIMEOUT_MINUTES || 1440;

export { dynoape, dynoapeAPI, getSortedPositions, getSortedCompetences, dbPortalTimeDiffMinutes,
    taskTimeoutMinutes, addAccessTokenInterceptor, ErrorHandler, getSortedShiftTypes, rawDynoapeAPI };