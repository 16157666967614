
export class FeatureToggles {
  static FEATURE_TOGGLE_RUNNER_MODULE = {
    name() {
      return "FEATURE_TOGGLE_RUNNER_MODULE";
    },
    envVar() {
      return "REACT_APP_FEATURE_TOGGLE_RUNNER_MODULE_ENABLED";
    },
    description() {
      return `Toggle for enabling/disabling the feature of runners. 
             Toggle is off if either env var ${this.envVar()} is missing or set to 'false'. 
            Toggle is on if env var ${this.envVar()} is set to 'true'`;
    },
    isEnabled() {
      return FeatureToggles.envVarIsTrue(this.envVar());
    },
  };
  static FEATURE_TOGGLE_ORG_EMPLOYEE_MODULE = {
    name() {
      return "FEATURE_TOGGLE_ORG_EMPLOYEE_MODULE";
    },
    envVar() {
      return "REACT_APP_FEATURE_TOGGLE_ORG_EMPLOYEE_MODULE_ENABLED";
    },
    description() {
      return `Toggle for enabling/disabling the feature of organization employees (Linking employees from different departments). 
             Toggle is off if either env var ${this.envVar()} is missing or set to 'false'. 
            Toggle is on if env var ${this.envVar()} is set to 'true'`;
    },
    isEnabled() {
      return FeatureToggles.envVarIsTrue(this.envVar());
    },
  };

  static FEATURE_TOGGLE_CRITICAL_HOURS_ENABLED = {
    name() {
      return "FEATURE_TOGGLE_CRITICAL_HOURS_ENABLED";
    },
    envVar() {
      return "REACT_APP_FEATURE_TOGGLE_CRITICAL_HOURS_ENABLED";
    },
    description() {
      return `Toggle for enabling/disabling the feature of department critical hours. 
             Toggle is off if either env var ${this.envVar()} is missing or set to 'false'. 
            Toggle is on if env var ${this.envVar()} is set to 'true'`;
    },
    isEnabled() {
      return FeatureToggles.envVarIsTrue(this.envVar());
    },
  };
  static envVarIsTrue = (varName) => {
    return process.env[varName] === "true" || process.env[varName] === true;
  };
}
