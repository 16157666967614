import {InputRow} from "../lib/styled-components";
import React, {useContext} from "react";
import styled from "styled-components";
import {
    daySegmentNames,
    getDaysFromDatePatterns,
    hoursToLocalTime,
    localTimeToHours,
    sortShiftCodesByDaySegment
} from "../lib/common";
import Tooltip from "../lib/tooltip";
import moment from "moment";
import dynomiteContext from "./dynomite";
import { applyPatch } from "rfc6902";
import { v4 as uuidv4 } from 'uuid';


/**
 * Functional component for displaying summations of shift requirements. Called from either
 * DayInput or DayInputCustom. If DayInputCustom, the weeklyCoverDemands has appended dailyCoverPatches
 * @param task
 * @param employees
 * @param customDay
 * @returns {JSX.Element}
 * @constructor
 */
export const ShiftStats = ({task, employees, customDay=false}) => {
    const [monday, sunday] = [
        moment(customDay).startOf('isoWeek').format('YYYY-MM-DD'),
        moment(customDay).endOf('isoWeek').format('YYYY-MM-DD')
    ];
    const dynomite = useContext(dynomiteContext);
    const id = () => uuidv4();
    const key = (...args) => args.join("-");
    const empty = (key) => <p key={key}><label>-</label></p>;
    const weeklyCoverDemandsDays = Object.keys(task.config.weeklyCoverDemands).filter(day => day !== "HOLIDAYS");
    const fullVacancyRateHours = task.config.fullVacancyRateHours;
    const startDate = customDay ? moment(monday) : moment(task.config.startDate);
    const endDate = customDay ? moment(sunday) : startDate.clone().add(parseInt(task.config.nrOfWeeks), 'weeks').subtract(1, 'day');
    const days = {
        "MONDAY": "Man",
        "TUESDAY": "Tir",
        "WEDNESDAY": "Ons",
        "THURSDAY": "Tors",
        "FRIDAY": "Fre",
        "SATURDAY": "Lør",
        "SUNDAY": "Søn"
    };

    const usedShiftCodes = sortShiftCodesByDaySegment([...new Set(weeklyCoverDemandsDays.flatMap(day => {
        return Object.keys(task.config.weeklyCoverDemands[day]);
    }))], Object.values(task.config.shiftTypes));

    const unusedShiftCodes = sortShiftCodesByDaySegment(
        Object.keys(task.config.shiftTypes).filter(sh => !usedShiftCodes.includes(sh)),
        Object.values(task.config.shiftTypes)
    );

    const shiftCodesUsedInDailyCoverPatches = Object.entries(task.config.dailyCoverPatches).reduce((acc, [date, patches]) => {
        patches.forEach(patch => {
            const pathKey = patch.path.split("/")[1]
            acc[pathKey] = [...(acc[pathKey] || []), moment(date).format('DD/MM/YYYY')];
        });
        return acc;
    }, {});

    Object.keys(shiftCodesUsedInDailyCoverPatches).forEach(pathKey => {
        shiftCodesUsedInDailyCoverPatches[pathKey].sort((a, b) => {
            return moment(a, 'DD/MM/YYYY').toDate() - moment(b, 'DD/MM/YYYY').toDate();
        });
    });

    const getCoreStarts = (codes) => {
        return codes.map(sh => task.config.shiftTypes[sh].coreTimeStart);
    }

    const getCoreEnds = (codes) => {
        return codes.map(sh => {
            let type = task.config.shiftTypes[sh];
            let start = localTimeToHours(type.coreTimeStart);
            let end = parseFloat(start) + type.shiftHoursMin;
            return hoursToLocalTime(end)
        });
    }

    const getWeeklyDemandsElements = new Map(Object.keys(days).map(day => [day, usedShiftCodes.map(sh => {
        let val = "0 (0)";
        if(weeklyCoverDemandsDays.includes(day)) {
            if(Object.keys(task.config.weeklyCoverDemands[day]).includes(sh)) {
                val =  task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"] + " (" +
                    task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"] + ")";
            }
        }
        return <p key={id()}><label>{val}</label></p>
    })]));

    const getWeeklyDemandsSumElement = usedShiftCodes.map(sh => {
        let min = 0;
        let max = 0;
        weeklyCoverDemandsDays.forEach(day => {
            if(Object.keys(task.config.weeklyCoverDemands[day]).includes(sh)) {
                min += parseInt(task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"]);
                max += parseInt(task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"]);
            }
        })
        return <p key={id()}><label>{min + " (" + max + ")"}</label></p>
    })

    const weeklyDemandsPerSegment = new Map(Object.keys(days).map(day => {
        let minSum = 0;
        let maxSum = 0;
        let sums = [day, ["D", "A", "N", "L"].map(ds => {
            let min = 0;
            let max = 0;
            Object.keys(task.config.weeklyCoverDemands[day]).forEach(sh => {
                if(task.config.shiftTypes[sh].daySegment === ds) {
                    min += task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"];
                    max += task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"];
                }
            })
            minSum += min;
            maxSum += max;
            return <p key={id()}><label>{min + " (" + max + ")"}</label></p>;
        })];
        sums[1].push(<p key={id()}><label>{minSum + " (" + maxSum + ")"}</label></p>);
        return sums;
    }));

    const weeklyDemandsSumPerSegment = ["D", "A", "N", "L"].map(ds => {
        let min = 0;
        let max = 0;
        weeklyCoverDemandsDays.forEach(day => {
           Object.keys(task.config.weeklyCoverDemands[day]).forEach(sh => {
               let type = task.config.shiftTypes[sh];
               if(type.daySegment === ds) {
                   min += task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"];
                   max += task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"];
               }
           })
        });
        return {ds: ds, min: min, max: max};
    });

    const getTurnusDemands = () => {
        const weeks = Array.from({ length: parseInt(task.config.nrOfWeeks) }, (_, weekIndex) => {
            const weekStart = startDate.clone().add(weekIndex * 7, 'days');
            return Array.from({ length: 7 }, (_, dayIndex) => {
                const day = weekStart.clone().add(dayIndex, 'days');
                return day.isSameOrBefore(endDate) ? day.format('YYYY-MM-DD') : null;
            }).filter(Boolean);
        });

        const dailyCoverPatches = task.config.dailyCoverPatches;
        const holidays = Object.keys(task.config.holidays);
        const weeklyCoverDemands = JSON.parse(JSON.stringify(task.config.weeklyCoverDemands));
        const demands = {}
        for(const week of weeks) {
            for(const date of week) {
                demands[date] = weeklyCoverDemands[
                    holidays.includes(date) && ![0, 6].includes(moment(date).day())
                        ? 'HOLIDAYS'
                        : moment(date).format('dddd').toUpperCase()
                    ];

                if(!Object.keys(dailyCoverPatches).includes(date)) continue;
                const demand = JSON.parse(JSON.stringify(demands[date]));
                applyPatch(demand, dailyCoverPatches[date])
                demands[date] = demand;
            }
        }
        return demands;
    }

    const turnusDemands = getTurnusDemands();

    const manYearsPerDayPerSegment = Object.keys(days).reduce((acc, day) => {
        ["D", "A", "L", "N"].forEach(ds => {
            acc[day][ds] = Object.keys(task.config.weeklyCoverDemands[day]).reduce((shiftsAcc, code) => {
                let type = task.config.shiftTypes[code];
                if(type.daySegment !== ds) return shiftsAcc;
                const demand = task.config.weeklyCoverDemands[day][code];
                shiftsAcc.min += ((parseFloat(type.shiftHoursMin) - (parseFloat(type.breakMinutes) / 60)) * parseInt(demand.minimumNrOfEmployees)) / parseFloat(fullVacancyRateHours);
                shiftsAcc.max += ((parseFloat(type.shiftHoursMax) - (parseFloat(type.breakMinutes) / 60)) * parseInt(demand.maximumNrOfEmployees)) / parseFloat(fullVacancyRateHours);
                return shiftsAcc;
            }, {min: 0.0, max: 0.0})
        })
        return acc;
    }, {"MONDAY": {}, "TUESDAY": {}, "WEDNESDAY": {}, "THURSDAY": {}, "FRIDAY": {}, "SATURDAY": {}, "SUNDAY": {}})

    let manYears = 0.0;

    const manYearsPerSegment = ["D", "A", "N", "L"].map(ds => {
        let hours = 0.0;
        Object.values(turnusDemands).forEach(demands => {
            Object.entries(demands).forEach(([code, demand]) => {
                let type = task.config.shiftTypes[code];
                if(type.daySegment !== ds) return;
                hours += (type.shiftHoursMin - (parseFloat(type.breakMinutes) / 60)) * parseInt(demand.minimumNrOfEmployees);
            })
        })
        const min = (hours/fullVacancyRateHours/((endDate.diff(startDate, 'days') + 1) / 7)).toFixed(2);
        manYears += parseFloat(min);
        return <p key={id()}><label>{min}</label></p>;
    });

    let upperManYears = 0.0;

    const upperManYearsPerSegment = ["D", "A", "N", "L"].map(ds => {
        let hours = 0.0;
        Object.values(turnusDemands).forEach(demands => {
            Object.entries(demands).forEach(([code, demand]) => {
                let type = task.config.shiftTypes[code];
                if(type.daySegment !== ds) return;
                hours += (type.shiftHoursMax - (parseFloat(type.breakMinutes) / 60)) * parseInt(demand.maximumNrOfEmployees);
            })
        })
        const max = (hours/fullVacancyRateHours/((endDate.diff(startDate, 'days') + 1) / 7)).toFixed(2);
        upperManYears += parseFloat(max);
        return <p key={id()}><label>{max}</label></p>;
    })

    const calculateAvailableManYears = () => {
        if(customDay) {
            return parseFloat(
                employees.filter(e => e.enabled)
                    .filter(e =>
                        getDaysFromDatePatterns(dynomite, [...(e.vacationPatterns || []), ...(e.paidLeavePatterns || [])], monday, sunday, ["vacation", "paidLeave"]).length === 0)
                    .map(e => e.vacancyRate)
                    .reduce((acc, curr) => acc + curr, 0) / 100
            ).toFixed(2);
        }

        return parseFloat(
            employees.filter(e => e.enabled)
                .map(e => {
                        const vacRate = parseFloat(e.vacancyRate);
                        const absence = [... new Set(getDaysFromDatePatterns(
                            dynomite,
                            [...(e.vacationPatterns || []), ...(e.paidLeavePatterns || [])],
                            startDate.format('YYYY-MM-DD'),
                            endDate.format('YYYY-MM-DD'),
                            ["vacation", "paidLeave"]
                        ))];
                        return vacRate - (vacRate * (absence.length / (endDate.diff(startDate, 'days') + 1)));
                    }
                ).reduce((acc, curr) => acc + curr, 0) / 100
        ).toFixed(2);
    }

    const availableManYears = calculateAvailableManYears();

    const availableManYearsSummary = availableManYears > upperManYears ? + 1 : (availableManYears < manYears ? - 1 : 0)

    const traitSummations =
        Object.keys(task.config.traits)
            .reduce((acc, trait) => {
                acc[trait] = Object.keys(days)
                    .reduce((dayAcc, day) => {
                        dayAcc[day] = { D: 0, A: 0, N: 0, L: 0 };
                        Object.entries(task.config.weeklyCoverDemands[day])
                            .forEach(([code, demand]) => {
                                if (demand.traitRequirements[trait]) {
                                    const segment = task.config.shiftTypes[code].daySegment;
                                    dayAcc[day][segment] += parseInt(demand.traitRequirements[trait].value);
                                }
                            });
                        return dayAcc;
                    }, {});

                return acc;
            }, {});

    const posSummations =
        Object.keys(task.config.positions)
            .reduce((acc, pos) => {
                acc[pos] = Object.keys(days)
                    .reduce((dayAcc, day) => {
                        dayAcc[day] = { D: 0, A: 0, N: 0, L: 0 };
                        Object.entries(task.config.weeklyCoverDemands[day])
                            .forEach(([code, demand]) => {
                                if (demand.positionRequirements[pos]) {
                                    const segment = task.config.shiftTypes[code].daySegment;
                                    dayAcc[day][segment] += parseInt(demand.positionRequirements[pos]);
                                }
                            });
                        return dayAcc;
                    }, {});

                return acc;
            }, {});

    const header = (txt) => {
        return (<label style={{fontSize: "16px"}}><b>{txt}</b></label>)
    }

    return (
        <div>
            {/** Summary of the demands for each shift type each day **/}
            {header(`Vaktkategori sammendrag ${customDay ? "denne uken" : "i ordinær uke"}`)}
            <p></p>
            <InputRow>
                <InputDivider style={{minWidth: "230px"}}>
                    <p><label><b>Vaktkategori</b></label></p>
                    <p><label>Dagvakt</label></p>
                    <p><label>Kveldsvakt</label></p>
                    <p><label>Nattevakt</label></p>
                    <p><label>Langvakt/mellomvakt</label></p>
                    <p><label>Sum</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider></InputDivider>
                {Object.entries(days).map(([day, trans]) =>
                    <InputDivider key={id()}>
                        <p key={id()}><label><b>{trans}</b></label></p>
                        {weeklyDemandsPerSegment.get(day)}
                    </InputDivider>
                )}
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Sum</b></label></p>
                    {weeklyDemandsSumPerSegment.map(dsSum => <p key={id()}><label>{dsSum.min + " (" + dsSum.max + ")"}</label></p>)}
                    <Hr ml="-786px" w="822px"></Hr>
                    <p key={id()}><label>{weeklyDemandsSumPerSegment.reduce((acc, obj) => [acc[0] + obj.min, acc[1] + obj.max], [0, 0]).join(' (')})</label></p>
                </InputDivider>
            </InputRow>
            <p></p>

            {/** Summary of the demands for each position per shift type per day **/}
            {header(`Stilling sammendrag ${customDay ? "denne uken" : "i ordinær uke"}`)}
            <p></p>
            <InputRow>
                <InputDivider style={{minWidth: "230px"}}>
                    <p><label><b>Stilling (D/A/N/L)</b></label></p>
                    {Object.values(task.config.positions).map(name => <p key={id()}><label>{name}</label></p>)}
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider></InputDivider>
                {Object.entries(days).map(([day, trans]) =>
                    <InputDivider key={id()}>
                        <p key={id()}><label><b>{trans}</b></label></p>
                        {Object.keys(task.config.positions)
                            .filter(pos => posSummations[pos])
                            .map(pos => (
                                <p key={id()}><label>{[
                                    posSummations[pos][day]["D"],
                                    posSummations[pos][day]["A"],
                                    posSummations[pos][day]["N"],
                                    posSummations[pos][day]["L"]].join("/")}</label></p>)
                            )
                        }
                    </InputDivider>
                )}
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Sum</b></label></p>
                    {Object.keys(posSummations).map(pos => {
                        const sums = Object.values(posSummations[pos]).reduce((acc, sum) => {
                            acc["D"] += sum["D"];
                            acc["A"] += sum["A"];
                            acc["N"] += sum["N"];
                            acc["L"] += sum["L"];
                            return acc;
                        }, {D: 0, A: 0, N: 0, L: 0});
                        return (<p key={id()}><label>{[sums["D"], sums["A"], sums["N"], sums["L"]].join("/")}</label></p>)
                    })}
                </InputDivider>
            </InputRow>
            <p></p>

            {/** Summary of the demands for each trait per shift type per day **/}
            {header(`Spesialkompetanse sammendrag ${customDay ? "denne uken" : "i ordinær uke"}`)}
            <p></p>
            <InputRow>
                <InputDivider style={{minWidth: "230px"}}>
                    <p><label><b>Spesialkompetanse (D/A/N/L)</b></label></p>
                    {Object.values(task.config.traits).map(name => <p key={id()}><label>{name}</label></p>)}
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider></InputDivider>
                {Object.entries(days).map(([day, trans]) =>
                    <InputDivider key={id()}>
                        <p key={key("trait", "sum", day, "p")}><label><b>{trans}</b></label></p>
                        {Object.keys(task.config.traits)
                            .filter(trait => traitSummations[trait])
                            .map(trait => (
                                <p key={key("trait", trait, "sum", day, "p")}><label>{[
                                    traitSummations[trait][day]["D"],
                                    traitSummations[trait][day]["A"],
                                    traitSummations[trait][day]["N"],
                                    traitSummations[trait][day]["L"]].join("/")}</label></p>)
                            )
                        }
                    </InputDivider>
                )}
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Sum</b></label></p>
                    {Object.keys(traitSummations).map(trait => {
                        const sums = Object.values(traitSummations[trait]).reduce((acc, sum) => {
                            acc["D"] += sum["D"];
                            acc["A"] += sum["A"];
                            acc["N"] += sum["N"];
                            acc["L"] += sum["L"];
                            return acc;
                        }, {D: 0, A: 0, N: 0, L: 0});
                        return (<p key={id()}><label>{[sums["D"], sums["A"], sums["N"], sums["L"]].join("/")}</label></p>)
                    })}
                </InputDivider>
            </InputRow>
            <p></p>

            {/** Summary of the required man years for each day **/}
            {header(`Årsverk per dag ${customDay ? "denne uken" : "i ordinær uke"}`)}
            <p></p>
            <InputRow>
                <InputDivider style={{minWidth: "230px"}}>
                    <p><label><b>Vaktkategori</b></label></p>
                    <p><label>Dagvakt</label></p>
                    <p><label>Kveldsvakt</label></p>
                    <p><label>Nattevakt</label></p>
                    <p><label>Langvakt/mellomvakt</label></p>
                    <p><label>Sum</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider></InputDivider>
                {Object.entries(days).map(([day, trans]) =>
                    <InputDivider key={id()}>
                        <p key={id()}><label><b>{trans}</b></label></p>
                        {["D", "A", "N", "L"].map(ds =>
                            <p key={id()}><label>
                                {manYearsPerDayPerSegment[day][ds].min.toFixed(2)} ({manYearsPerDayPerSegment[day][ds].max.toFixed(2)})
                            </label></p>
                        )}
                        <p key={id()}><label>
                            {Object.values(manYearsPerDayPerSegment[day]).reduce((acc, val) => {
                                acc += val.min
                                return acc;
                            }, 0.0).toFixed(2)} (
                            {Object.values(manYearsPerDayPerSegment[day]).reduce((acc, val) => {
                                acc += val.max
                                return acc;
                            }, 0.0).toFixed(2)}
                            )
                        </label></p>
                    </InputDivider>
                )}
                <InputDivider></InputDivider>
                <InputDivider>
                    <p key={id()}><label><b>Sum</b></label></p>
                    {["D", "A", "N", "L"].map(ds => {
                        return (
                            <div key={id()}>
                                <p key={key(id())}><label>
                                {Object.keys(manYearsPerDayPerSegment).reduce((acc, day) => {
                                    acc += manYearsPerDayPerSegment[day][ds].min;
                                    return acc;
                                }, 0.0).toFixed(2)} (
                                    {Object.keys(manYearsPerDayPerSegment).reduce((acc, day) => {
                                        acc += manYearsPerDayPerSegment[day][ds].max;
                                        return acc;
                                    }, 0.0).toFixed(2)})
                                </label></p>
                            </div>
                        )
                    })}
                    <Hr ml="-813px" w="888px"></Hr>
                    <p><label>
                        {Object.values(manYearsPerDayPerSegment).reduce((acc, shifts) => {
                            acc += Object.values(shifts).reduce((shiftsAcc, value) => {
                                shiftsAcc += value.min;
                                return shiftsAcc;
                            }, 0.0)
                            return acc;
                        }, 0.0).toFixed(2)} (
                        {Object.values(manYearsPerDayPerSegment).reduce((acc, shifts) => {
                            acc += Object.values(shifts).reduce((shiftsAcc, value) => {
                                shiftsAcc += value.max;
                                return shiftsAcc;
                            }, 0.0)
                            return acc;
                        }, 0.0).toFixed(2)}
                        )
                    </label></p>
                </InputDivider>
            </InputRow>
            <p></p>
            <p></p>

            {/**
             If custom week, this shows the summation of the required man years for the given week
             If ordinary BP, this shows the summation of the required man years for the whole turnus period
             **/}
            {header(`Årsverk ${customDay ? "denne uken" : "i hele turnusperioden"}`)}
            <p></p>
            <InputRow>
                <InputDivider style={{minWidth: "230px"}}>
                    <p><label><b>Vaktkategori</b></label></p>
                    <p><label>Dagvakt</label></p>
                    <p><label>Kveldsvakt</label></p>
                    <p><label>Nattevakt</label></p>
                    <p><label>Langvakt/mellomvakt</label></p>
                    <p><label>Sum</label></p>
                </InputDivider>
                <InputDivider>
                    <p><label><b>Minimumsbehov</b></label></p>
                    {manYearsPerSegment}
                    <p><label>{manYears.toFixed(2)}</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Maksimumsbehov</b></label></p>
                    {upperManYearsPerSegment}
                    <p><label>{upperManYears.toFixed(2)}</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Tilgjengelig årsverk {customDay ? "denne uken" : ""}</b></label></p>
                    {Array.from({length: 4}, () => empty(id()))}
                    <p><label>{availableManYears}</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Oppsummering</b></label></p>
                    {Array.from({length: 4}, () => empty(id()))}
                    <Hr ml="-795px" w="922px"></Hr>
                    <p style={{color: availableManYearsSummary > 0 ? "blue" : (availableManYearsSummary < 0 ? "red": "green")}}>
                        <label>{availableManYearsSummary > 0 ? "OVERBEMANNET" : (availableManYearsSummary < 0 ? "UNDERBEMANNET": "BALANSE")}</label>
                    </p>
                </InputDivider>
            </InputRow>
            <p></p>

            {/** Summary of the demands for each shift code **/}
            {header(`Vaktkode sammendrag ${customDay ? "denne uken" : "i ordinær uke"}`)}
            <p></p>
            <InputRow>
                <InputDivider>
                    <p><label><b>Kode</b></label></p>
                    {usedShiftCodes.map(sh =>
                        <div key={id()}>
                            <p key={id()}><label>{sh}</label></p>
                        </div>
                    )}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid start</b></label></p>
                    {getCoreStarts(usedShiftCodes).map(t => <p key={id()}><label>{t}</label></p>)}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid slutt</b></label></p>
                    {getCoreEnds(usedShiftCodes).map(h => <p key={id()}><label>{h}</label></p>)}
                </InputDivider>
                <InputDivider></InputDivider>
                {Object.entries(days).map(([day, trans]) =>
                    <InputDivider key={id()}>
                        <p key={id()}><label><b>{trans}</b></label></p>
                        {getWeeklyDemandsElements.get(day)}
                    </InputDivider>
                )}
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Sum</b></label></p>
                    {getWeeklyDemandsSumElement}
                </InputDivider>
            </InputRow>
            <p></p>

            {/** Unused shift codes in the BP **/}
            {header("Ubrukte vaktkoder")}
            <p></p>
            <InputRow>
                <InputDivider>
                    <p><label><b>Kode</b></label></p>
                    {unusedShiftCodes.map(sh =>
                        <div key={id()}>
                            <p key={id()}><label>{sh}</label></p>
                        </div>
                    )}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Vaktkategori</b></label></p>
                    {unusedShiftCodes.map(sh =>
                        <div key={id()}>
                            <p key={id()}><label>{daySegmentNames[task.config.shiftTypes[sh].daySegment]}</label></p>
                        </div>
                    )}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid start</b></label></p>
                    {getCoreStarts(unusedShiftCodes).map(t => <p key={id()}><label>{t}</label></p>)}
                </InputDivider>
                <InputDivider style={{minWidth: "200px"}}>
                    <p><label><b>Kjernetid slutt</b></label></p>
                    {getCoreEnds(unusedShiftCodes).map(h => <p key={id()}><label>{h}</label></p>)}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Brukt i tilpasset bemanningsplan</b></label></p>
                    {unusedShiftCodes.map(sh =>
                        <>
                            <div
                                data-for={key(sh, "unused", "comment", "div")}
                                data-tip={Object.keys(shiftCodesUsedInDailyCoverPatches).includes(sh)
                                    ? shiftCodesUsedInDailyCoverPatches[sh]
                                        .map(date => task.config.holidays.includes(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')) ? `<div style='color: red;'>${date}</div>` : `<div>${date}</div>`)
                                        .join("")
                                    : ""
                            }
                                key={key(sh, "unused", "comment", "div")}>
                                <p key={key(sh, "unused", "comment", "p")}>
                                    <label>
                                        {Object.keys(shiftCodesUsedInDailyCoverPatches).includes(sh) ? "Hold musen her for å se dager" : "."}
                                    </label></p>
                            </div>
                            <Tooltip id={key(sh, "unused", "comment", "div")} />
                        </>
                    )}
                </InputDivider>
            </InputRow>
            <p></p>
        </div>
    );
}
const InputDivider = styled.div`
  min-width: 70px;
  margin-bottom: 10px;
`
const Hr = styled.hr`
    width: ${props => props.w};
    margin-left: ${props => props.ml};
    margin-top: -5px;
    position: absolute;
    border-top: 2px solid black;
`;