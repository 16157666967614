import React, {useEffect, useState} from "react";
import {
    dynomiteErrorPanel,
    scrollHeight,
    shortenStr,
    sortShiftTypesByDaySegment
} from "../../lib/common";
import moment from "moment/moment";
import { format, parseISO, getISOWeek, isFriday } from "date-fns";
import { nb } from "date-fns/locale";
import {dynoapeAPI} from "../../api/dynoape";
import {Button, Flex} from "rendition";
import Tooltip from "../../lib/tooltip";
import {WeekJumper} from "../../lib/styled-components";
import {PlannerWrapper, ReactGridWrapper} from "./employee-date-pattern-cases";
import {ReactGrid} from "@silevis/reactgrid";
import {CheckboxCellTemplate} from "../grid-components/checkbox-cell-template";
import {PatternCase} from "../../lib/pattern-case";
import {DropdownCellTemplate} from "../grid-components/dropdown-cell-template";
import {DropdownSelectCellTemplate} from "../grid-components/dropdown-select-cell-template";
import {DisabledInfoCellTemplate} from "../grid-components/disabled-info-cell-template";
import {holidayOptions, redDaysOptions} from "../../lib/options";
import "../grid-components/grid-styles.css";

export default ({
    somePatternCaseIsDirty, setSomePatternCaseIsDirty, viewStartIndex, setViewStartIndex, setViewFlipSize, viewFlipSizeRef,
    taskSelect, searchBar,
    departmentId,
    setSelectedTaskOption,
    department, holidays, processAndSetHolidays,
    employees,
    employeesAvailabilities,
    setEmployeesWithAvailabilities,
    requirements,
    setSelectedTaskInfo,
    patternCase,
    reset,
    getReactGridRows, getReactGridHeaderRow,
    showSavingModal, setShowSavingModal, saveModal,
    dynomite, validate, configErrors, configWarnings, dynomiteInternalError,
    startDate, setStartDate, nrOfWeeks, setNrOfWeeks,
    openedDropdownLocation, findAndSetOpenedDropdownCellLocation,
    searchFilters, applySearchFiltersToEmployees, routerPrompt}) => {

    const [toSave, setToSave] = useState(new Map());
    const nrInView = 18;
    const [toSaveAvailabilities, setToSaveAvailabilities] = useState(new Map());
    const [days, setDays] = useState(undefined)

    const [columns, setColumns] = useState(undefined);

    useEffect(() => {
        setDays(holidays.slice(0, nrInView))
        scrollHeight(2000);
        return () => {
            reset();
        }
    }, [])

    useEffect(() => {
        if(
            (somePatternCaseIsDirty && toSave.size > 0)
            || (!somePatternCaseIsDirty && toSave.size === 0)
        ) return;
        setSomePatternCaseIsDirty(toSave.size > 0);
    }, [toSave])

    useEffect(() => {
        validate({employeesParam: applySearchFiltersToEmployees(employees).map(e => getLocalOrGlobalEmployee(e.id))});
    }, [toSave, employees, searchFilters, days])

    /**
     * Helper function for getting employee data. If there are unsaved employee data, we fetch it from local storage.
     * If not, the employee is returned
     * @param employeeId
     * @returns {*}
     */
    const getLocalOrGlobalEmployee = (employeeId) => {
        return toSave?.get(employeeId) || employees.find(e => e.id === employeeId);
    }

    /**
     * Helper function to return availability either from local user or parent user
     * @param {string} employeeId 
     * @param {string} day 
     * @returns 
     */
    const getEmplAvailability = (employeeId, day) => {
        let employeeAvailability = toSave.has(employeeId) ?
            toSaveAvailabilities?.get(employeeId) :
            employeesAvailabilities?.get(employeeId);
        return [...(employeeAvailability?.get(day) || new Map()).values()]
    }

    /**
     * Method for creating a valid holiday pattern based on either an existing or an empty one
     * @param pattern
     * @returns {{summary: string, includeDays: (*|*[]), optimize: boolean, series: null, kind: string, daySegments: string[], disabled: boolean, id: *, excludeDays: *[]}}
     */
    const validHolidayPattern = (pattern) => {
        return {
            id: pattern?.id,
            summary: "Helligdager på jobb",
            series: null,
            includeDays: pattern?.includeDays || [],
            excludeDays: [],
            daySegments: ["D", "A", "N", "L"],
            disabled: false,
            optimize: false,
            kind: "working"
        }
    }

    /**
     * UseEffect for creating the columns in the sheet.
     * 
     * The followin columns exist:
     * - Row nr
     * - employee name
     * - employee position name (alias)
     * - daysegments which the employee can work (ordered by D, A, L, N)
     * - employees redDaysMaxSequence
     * - employees movableHolidaysMaxPerTurnus
     * - number of working holidays
     * - one column for each holiday in the period
     * 
     * On the bottom, there are summations for each given day:
     * - demand (summed mins and maxes)
     * - covers
     * - diffs if cover is outside demand interval
     */
    useEffect(() => {
        if(!days) return;
        const daySegmentFilters = searchFilters.daySegments?.length
            ? searchFilters.daySegments
            : ['D', 'A', 'L', 'N'];
        const eprops = {demand: '', cover: '', diff: ''};
        setColumns([
            {
                columnId: "nr", width: 40, text: "Rad",
                item: ({ idx }) => ({ type: "header", text: `${idx + 1}` }),
                ...eprops
            }, {
                columnId: "name", width: 265, text: "Navn",
                item: ({ employee }) => ({ type: "header", text: `${shortenStr(employee.name, 30)}` }),
                demand: "Bem.krav", cover: "Dekning", diff: "Differanse"
            }, {
                columnId: "position", width: 150, text: "Stilling",
                item: ({ employee }) => ({ type: "header", text: shortenStr(`${employee.restPosition?.alias}`) }),
                ...eprops
            }, {
                columnId: "daySegment", width: 150, text: "Vaktkategorier",
                item: ({ employee }) => ({ type: "header", text: `${[...new Set(sortShiftTypesByDaySegment(employee.restShiftTypes)?.map(sh => sh.daySegment))]?.join(', ')}` }),
                ...eprops
            }, {
                columnId: "nrOfWorking", width: 130, text: "Antall helligdager",
                item: ({ employee }) => ({ type: "header", text: resolveNrOfWorkingHolidays(employee.id).toString() }),
                ...eprops
            }, {
                columnId: "redDaysMaxSequence", width: 120, text: "Maks. antall røde dager på rad",
                item: ({ employee }) => ({ type: "dropdown-number", text: getLocalOrGlobalEmployee(employee.id).redDaysMaxSequence, values: redDaysOptions }),
                ...eprops
            }, {
                columnId: "movableHolidaysMaxPerTurnus", width: 180, text: "Maks. antall bevegelige høytidsdager",
                item: ({ employee }) => ({ type: "dropdown-number", text: getLocalOrGlobalEmployee(employee.id).movableHolidaysMaxPerTurnus, values: holidayOptions }),
                ...eprops
            }
        ].concat(days.map(d => {
            const demand = requirements.has(d) ? 
                requirements.get(d).reduce(
                    (acc, shift_req) => {
                        if(daySegmentFilters.includes(shift_req[1])) {
                            acc.min += shift_req[2];
                            acc.max += shift_req[3];
                            return acc;
                        }
                        return acc;
                    }, {min: 0, max: 0})
                : {min: 0, max: 0};
            const cover = applySearchFiltersToEmployees(employees, days[0], days[days.length -1]).reduce(
                (count, e) => count + getEmplAvailability(e.id, d).reduce((a, v) => a || v > 0, false),
                0
            );
            let diff = cover > demand.max
                ? cover - demand.max
                : cover < demand.min ? cover - demand.min
                : 0;
            let day = parseISO(d, {representation: 'date'})

            return {
                columnId: d,
                included: true,
                width: 130,
                text: `${d} \n uke ${getISOWeek(day)} (${format(day, 'EEE', { locale: nb })}) \n ${
                    d.endsWith('12-24')
                        ? 'Julaften'
                        : d.endsWith('12-31')
                            ? 'Nyttårsaften'
                            : department.countryRules.holidays[d]
                }`,
                demand: demand.min > 0 || demand.max > 0 ? `${demand.min} : ${demand.max}` : '',
                cover: cover.toString(),
                diff: diff > 0 ? '+' + diff : diff.toString(),
                item: ({ employee }) => {
                    let emplAvail = getEmplAvailability(employee.id, d);
                    if (isFriday(day)) emplAvail = [emplAvail[0]]
                    if (emplAvail.reduce((a, v) => a && v <= -1, true)) {
                        switch (emplAvail[0]) {
                            case -3: return { type: 'empty-blue', text: `P`, nonEditable: true}
                            case -2: return { type: 'empty-pink', text: `FE`, nonEditable: true}
                            default: return { type: 'empty', text: '', nonEditable: true}
                        }
                    } else if (emplAvail.reduce((a, v) => a || (v > 0 && v != 0 && v != 2), false)) {
                        return { type: 'empty', text: 'Jobber', nonEditable: true }
                    } else {
                        return {
                            type: 'checkbox',
                            checked: emplAvail.reduce((a, v) => a || v == 2, false),
                            checkedText: 'Ja',
                            uncheckedText: 'Nei'
                        }
                    }
                }
            }
        })));
    }, [days, employees, toSave, openedDropdownLocation, searchFilters])

    /**
     * Methods for getting the total number of working holidays for an employee
     * in a turnus period.
     * 
     * @param employeeId
     * @returns {number}
     */
    const resolveNrOfWorkingHolidays = (employeeId) => {
        return days.reduce(
            (acc, day) =>
                acc + getEmplAvailability(employeeId, day).reduce((a, v) => a || v > 0, false),
            0
        )
    }

    /**
     * Task select handler method. Once a task is selected, we generate
     * the holidays in the turnus period which again triggers generate of
     * the columns displayed in the holiday planner
     * 
     * @param selectedTaskOption
     * @returns {Promise<void>}
     */
    const handleSelectTaskChange = async (selectedTaskOption) => {
        if(!selectedTaskOption) {
            reset();
            return processAndSetHolidays(department, moment().startOf('year').startOf('isoWeek'), 157);
        }

        setColumns(undefined);
        let t = await dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${selectedTaskOption.value}`);
        setStartDate(t.config.startDate);
        setNrOfWeeks(t.config.nrOfWeeks);
        const _startDate = moment(t.config.startDate)
        const hol = processAndSetHolidays(department, _startDate, t.config.nrOfWeeks);
        setDays(hol.slice(0, nrInView));

        setSelectedTaskInfo(t);
        setSelectedTaskOption({value: selectedTaskOption.value, label: selectedTaskOption.label});
        setViewStartIndex(0)
        setViewFlipSize(2);
        scrollHeight()
    }

    useEffect(() => {
        setDays(holidays.slice(0, nrInView))
    }, [holidays])

    /**
     * UseEffect for generating which weeks that should be present in the holiday planner.
     * Triggered whenever user viewStartIndex is changed (user selects to jump weeks)
     */
    useEffect(() => {
        setDays(holidays.slice(viewStartIndex, viewStartIndex + nrInView))
    }, [viewStartIndex])

    /**
     * Method for handling the changes in the sheet
     * @param changes
     */
    const handleChanges = (changes) => {
        findAndSetOpenedDropdownCellLocation(changes);
        let localToSave = new Map();
        changes.forEach(ch => {
            if(ch.newCell.isOpen && !ch.previousCell.isOpen) return;

            const employee = (localToSave?.get(ch.rowId) || toSave?.get(ch.rowId) || employees.find(e => e.id === ch.rowId));
            if(['redDaysMaxSequence', 'movableHolidaysMaxPerTurnus'].includes((ch.columnId))) {
                const val = Number(ch.newCell.text);
                if (!isNaN(val)) {
                    employee[ch.columnId] = val;
                }
            }
            const date = moment(ch.columnId).format('YYYY-MM-DD');
            const pattern = validHolidayPattern((employee.holidayPatterns || []).find(p => p))
            if (ch.newCell.checked === true) {
                if(!pattern.includeDays.includes(date)) {
                    pattern.includeDays.push(date)
                }
            } else {
                if(pattern.includeDays.includes(date)) {
                    pattern.includeDays = pattern.includeDays.filter(d => d !== date);
                }
            }
            localToSave.set(
                employee.id,
                {...employee, holidayPatterns: [pattern].filter(p => p)}
            );
        });
        const newToSave = new Map([...toSave, ...localToSave]);
        const newToSaveAvailabilities = new Map(
            [...newToSave].map(e => [
                e[0],
                new Map(dynomite.dynomite.employeeAvailability(
                    e[1],
                    moment(startDate).format('YYYY-MM-DD'),
                    moment(startDate).add(nrOfWeeks, 'weeks').format('YYYY-MM-DD'),
                ))
            ])
        )
        setToSave(newToSave);
        setToSaveAvailabilities(newToSaveAvailabilities);
    }

    /**
     * Method for saving employees and respective holiday patters
     * @returns {Promise<void>}
     */
    const save = async () => {
        setShowSavingModal({ display: true, saveCount: 0, toSave: toSave.size });
        for(const e of toSave.values()) {
            setShowSavingModal({ display: true, saveCount: showSavingModal.saveCount++, toSave: toSave.size, employee: e.name});
            for (const pattern of e.holidayPatterns) {
                const id = pattern.id;
                ['id', 'registered', 'updated'].forEach(field => delete pattern[field])
                if (id) {
                    await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${e.id}/pattern/${id}?type=${patternCase}`, pattern);
                } else {
                    await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee/${e.id}/pattern?type=${patternCase}`, pattern);
                }
            }
            await dynoapeAPI.emPatch(`/api/v1/department/${departmentId}/employee/${e.id}`,
                {
                    redDaysMaxSequence: e.redDaysMaxSequence,
                    movableHolidaysMaxPerTurnus: e.movableHolidaysMaxPerTurnus
                }
            );
        }
        setToSave(new Map());
        setToSaveAvailabilities(new Map());
        setShowSavingModal({ display: false, saveCount: 0, toSave: 0, employee: null })
        setEmployeesWithAvailabilities(
            (await dynoapeAPI.get(`/api/v1/department/${departmentId}/employees${PatternCase.ALL_PATTERNS}`))
                .filter(e => e.enabled)
                .sort(function (a, b) {
                    return a.priority === b.priority ? new Date(a.registered) - new Date(b.registered) : a.priority - b.priority
                })
        );
    }

    return (
        <PlannerWrapper>
            <p><label style={{fontSize: "16px"}}>
                Her kan du planlegge hvilke helligdager hver ansatt skal jobbe.
                <br/>
                <b>Merk: </b>
                <ul>
                    <li>For å blokkere en helligdag, gå til {PatternCase.translate(PatternCase.BLOCKED_PATTERN)}.</li>
                    <li>Helligdager som treffer en helgedag, må planlegges i {PatternCase.translate(PatternCase.WEEKEND_PATTERN)}.</li>
                    <li>En kan bare planlegge for dagvakt på helligdager som havner på fredag.</li>
                    <li>
                        <b>Jobber</b> betyr at en har planlagt den ansatte i Helgeplanleggeren. <i>Det kan også forekomme
                        hvis en har satt 2 eller mer på minimum vakter på rad for en vaktkategori.</i>
                    </li>
                </ul>
            </label></p>
            <br/>
            <Flex flexDirection="row">
                <Button primary style={{marginTop: "0px" }} onClick={() => save()}
                        data-for="save"
                        disabled={toSave.length === 0}
                        data-tip="Lagre dine helligdags-spesifikasjoner for de ansatte">Lagre</Button>
                <Tooltip id="save" />
                {taskSelect(handleSelectTaskChange)}
                {searchBar()}
                {columns &&
                    <WeekJumper flipSize={viewFlipSizeRef.current} setFlipSize={setViewFlipSize}
                                viewStartIndex={viewStartIndex} setViewStartIndex={setViewStartIndex}
                                nrInView={nrInView} jumpTitle="Hopp antall uker" totalNrOfColumns={holidays.length}>
                    </WeekJumper>
                }
            </Flex>
            {columns === undefined && <p>Laster...</p>}
            {columns && <ReactGridWrapper>
                <ReactGrid
                    customCellTemplates={{
                        'dropdown-number': DropdownCellTemplate,
                        'checkbox': CheckboxCellTemplate,
                        'dropdown': DropdownSelectCellTemplate,
                        'empty': DisabledInfoCellTemplate,
                        'empty-pink': DisabledInfoCellTemplate,
                        'empty-blue': DisabledInfoCellTemplate
                    }}
                    rows={[
                        getReactGridHeaderRow(columns, 'header', 'text', 70),
                        ...getReactGridRows(columns, employees.map(e => getLocalOrGlobalEmployee(e.id)), days[0], days[days.length -1])
                            .map((row, idx) => ({
                                ...row,
                                cells: row.cells.map(cell => ({
                                    ...cell,
                                    style: {
                                        background:
                                            cell.type === 'empty-blue' ? 'lightblue' :
                                            cell.type === 'empty-pink' ? 'pink' :
                                            idx % 2 !== 0 ? 'rgba(0,0,0,0.07)' :
                                            '',
                                        color: !row._employee.enabled ? '#888' : 'black'
                                    }
                                }))
                            })
                        ),
                        getReactGridHeaderRow(columns, 'demand', 'demand'),
                        getReactGridHeaderRow(columns, 'cover', 'cover'),
                        [getReactGridHeaderRow(columns, 'diff', 'diff')].map(row => ({
                            ...row,
                            cells: row.cells.map(cell => ({
                                ...cell,
                                text: cell.text.replaceAll('+', ''),
                                style: {
                                    color:
                                        cell.text === 'Differanse' ? 'black' :
                                        cell.text.includes('+') ? 'blue' :
                                        Number(cell.text) < 0 ? 'red' :
                                        'green'
                                }
                            }))
                        }))[0]
                    ]}
                    columns={columns}
                    stickyTopRows={1}
                    stickyBottomRows={3}
                    stickyLeftColumns={4}
                    onCellsChanged={handleChanges}
                    enableFillHandle
                    enableRangeSelection
                    enableRowSelection
                />
            </ReactGridWrapper>}
            {showSavingModal.display && saveModal()}
            {routerPrompt(toSave.length > 0)}
            {dynomiteErrorPanel(dynomite, configErrors, dynomiteInternalError)}
            {dynomiteErrorPanel(dynomite, configWarnings, dynomiteInternalError, 0, () => {}, true)}
        </PlannerWrapper>
    )
}