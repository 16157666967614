import React from "react";
import { Group } from "@visx/group";
import {
  scaleQuantile,
  scaleOrdinal,
  scaleLinear,
  scaleThreshold
} from "@visx/scale";
import { range } from "@visx/vendor/d3-array";
import { timeFormat } from "@visx/vendor/d3-time-format";
import {
  timeMonday,
  timeMonth,
  timeMonths,
  timeDays,
} from "@visx/vendor/d3-time";
import {
  fullSchemeRdBu,
  getDayOfWeek,
  getMonthWeek,
  daysInMonth,
} from "../../lib/common";
import {
  getISOWeek,
  getISOWeekYear
} from 'date-fns';
import moment from "moment";
import HeatmapLegend from "./heatmap-legend";


export default class CalendarDotHeatmap extends React.Component {
  /**
   * Generates the path for the month based on the cell size and months week
   * composition.
   *
   * @param {type} t0 - the starting date
   * @param {type} cellSize - the size of each cell
   * @return {type} the month path string
   */
  getMonthPath(t0, cellSize) {
    const d0 = getDayOfWeek(t0);
    const t1 = new Date(t0.getFullYear(), t0.getMonth() + 1, 0);
    const d1 = getDayOfWeek(t1);
    const w1 = getMonthWeek(t1);

    return `
      M${cellSize},${d0 * cellSize}
      H0 V${7 * cellSize}
      H${w1 * cellSize} V${(d1 + 1) * cellSize}
      H${(w1 + 1) * cellSize} V0
      H${cellSize}Z
    `;
  }

  /**
   * Calculates the visualization scale from scale inputs and data.
   *
   * If no scale information is present for 'ordinal' scale, the domain is
   * the set of unique values in the data.
   *
   * Domain of the scale is fetched from scaleInfo object, or calculated from
   * input data.
   *
   * @param {object} scaleInfo - The scale object. It's 'scale' prop of the
   *        component.
   * @param {array} colorScheme - array of colors for the colorscheme
   * @param {object} data - The data object.
   * @param {boolean} isDot - whether the scale is generated for dot or
   *        background part of visualization.
   * @return {array|null} The scale.
   */
  getScale(scaleInfo, colorScheme, data, isDot) {
    // Scale function
    const scaleFunc =
      scaleInfo.name === 'ordinal' ? scaleOrdinal() :
      scaleInfo.name === 'linear' ? scaleLinear() :
      scaleInfo.name === 'quantile' ? scaleQuantile() :
      scaleInfo.name === 'threshold' ? scaleThreshold() :
      scaleOrdinal();

    // Domain
    let dataId = isDot ? 0 : 1;
    let domain = [0, 0];
    switch (scaleInfo.name) {
      case 'ordinal':
        domain =
          Object.hasOwn(scaleInfo, 'domain') ? scaleInfo.domain
          : [...new Set(
              [...data.values()].reduce(
                (acc, monthMap) => acc.concat([...monthMap.values()].flat()),
                []
              ).map(d => d[dataId])
          )];
          break;
      case 'threshold':
        if (Object.hasOwn(scaleInfo, 'domain')) {
          domain = scaleInfo.domain;
        }
        // falls through
      case 'quantile':
      case 'linear':
        if (Object.hasOwn(scaleInfo, 'domain')) {
          domain = scaleInfo.domain;
        } else {
          domain = [...data.values()].reduce(
            (acc, monthMap) => {
              let monthValues = [...monthMap.values()].flat().map(d => d[dataId]);
              let monthMax = Math.max(monthValues);
              let monthMin = Math.min(monthValues);
              return [
                acc[0] > monthMin ? monthMin : acc[0],
                acc[1] < monthMax ? monthMax : acc[1],
              ];
            },
            [9999, -9999]
          );
        }
    }

    // Set color range
    const colRange =
      colorScheme[
        scaleInfo.name === 'ordinal' ? domain.length - 1 :
        scaleInfo.name === 'threshold' ? domain.length :
        Object.hasOwn(scaleInfo, 'categories') ? scaleInfo.categories :
        Math.min(domain[1] - domain[0], 5)
      ];
    
    // return constructed scale
    return scaleFunc.domain(domain).range(colRange);
  }

  /**
   * Render the calendar heatmap.
   *
   * @param data - The data to be rendered. Data are intended to be in
   *    the format of InternMap with two level of hierarchy. First is based
   *    on the month and the keys are supposed to be in format of YYYY-MM.
   *    If data are missing months, the error is rendered instead.
   *    Second level is based on the date. If the date is of invalid format, it
   *    is filtered out. The values of the second-level map are a list of tuples.
   *    Amount of elements in the list defines the amount of dots visible. Tuple
   *    encodes the values for dots and it's background. If any of the value is
   *    missing (unspecified) it will not be rendered.
   *    Dot and background values are working on independent scales.
   *    Supported values:
   *      - string - supported for only ordinal scale
   *      - number - supported by all scales
   *      - quantile - as a number
   * @param inMonths - The months to be rendered. If not provided, the months
   *    are distilled from the data.
   * @param width - Intended width of the calendar heatmap. The core of
   *    the visualization is the single block of the day, which has to be
   *    square. The component tries to figure out how many blocks are needed to
   *    be rendered and then the final width is calculated. So if the provided
   *    height is too small, the resulting width could be smaller as well.
   * @param height - Intended height of the calendar heatmap. The core of
   *    the visualization is the single block of the day, which has to be
   *    square. The component tries to figure out how many blocks are needed
   *    to be rendered and then the final width is calculated. So if
   *    the provided width is too small, the resulting height could be smaller
   *    as well.
   * @param margin - Intended margin around the calendar heatmap. Influences
   *    the calculation of the core square per day dimensions.
   * @param dotScale - parameter to controll which visualization scale to be
   *    used for dots. Parameter has to be an object with mandatory paremeter
   *    'name'. That parameter would controll which of other parameters would be
   *    used. Possible options for 'name' are:
   *      - "ordinal" - categorical scaling. Each name is a different color and
   *                    encoding. 
   *      - "linear" - continuous scaling, but with linear change encoded. In
   *                    other words, increase in the value will be encoded as
   *                    a consistent change of color. Not suitable if the data
   *                    are sparse or with high level of variation.
   *      - "quantile" - grouped continuous - each value is ordered to a
   *                    specific group - quantile. Groups are designed to be of
   *                    the same domain size.Visualization would then copntinue
   *                    as ordinal, where each group has it's color and encoding.
   *                    If present, 'categories' property could be used to
   *                    specify the amount of quantiles via 'categories'.
   *      - "threshold" - grouped continuous - similar to quantile, but "size"
   *                    of quantiles can change over available thresholds.
   *                    If used, 'domain' property is required and it has to be
   *                    an array of thresholds.
   * @param backScale - same as for dotScale, but for background.
   * @param monthsLabels - The labels for the months. Those are shown above each
   *      month in heatmap visualization. If not provided, the months format
   *      from the input data (YYYY-MM) will be used. Expected is an object with
   *      keys as the months (YYYY-MM format) and values as the labels.
   * @param dotColorScheme - The color scheme to be used in the dot vis.
   * @param backColorScheme - The color scheme to be used in the background vis.
   * @param undefinedColor - The color to be used for missing data.
   * @param dotLegend - If true, the legend will be shown for colors. 
   * @param dotLegendLabel - The label for the legend.
   * @param backLegend - If true, the legend will be shown for box/triangle.
   * @param backLegendLabel - The label for the shape legend.
   * @param weekSelection - The week selection to be used in the visualization.
   *    Will create an empty box around weeks to be selected. Selection accepts
   *    values of "year" and "start"-week, "count" of weeks and color of the
   *    selection.
   * @param hidden - If true, the component will not be rendered.
   * @return {JSX.Element} - The rendered calendar heatmap.
   */
  render() {
    const {
      data,
      inMonths,
      width,
      height,
      margin = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      dotScale = {name: 'ordinal'},
      backScale = {name: 'ordinal'},
      monthsLabels,
      dotColorScheme = fullSchemeRdBu,
      backColorScheme = fullSchemeRdBu,
      undefinedColor = "#dddddd",
      dotLegend = true,
      dotLegendLabel = "Legend",
      backLegend = true,
      backLegendLabel = "Legend",
      weekSelection = undefined,
      hidden = false,
    } = this.props;

    if (hidden) { return <div></div>;}

    const formatMonth = timeFormat("%Y-%m");
    const formatDay = timeFormat("%Y-%m-%d");

    let tmpMonths = inMonths ? inMonths : [...data.keys()].sort();
    if (Array.isArray(tmpMonths) && tmpMonths.length === 0) {
      return <div>Det er ikke nok data til å generere visualisering.</div>;
    }

    if (!Object.hasOwn(dotScale, 'name') || !Object.hasOwn(backScale, 'name')) {
      return <div>Ugyldig skala. Kan ikke generere visualisering.</div>;
    }

    const firstDay = timeMonth(new Date(tmpMonths[0]));
    const lastDay = timeMonth(new Date(tmpMonths[tmpMonths.length - 1]));
    lastDay.setDate(
      lastDay.getDate() + daysInMonth(tmpMonths[tmpMonths.length - 1]) - 1
    );
    if (isNaN(firstDay.getTime()) || isNaN(lastDay.getTime())) {
      return <div>Ugyldig dato. Kan ikke generere visualisering</div>;
    }

    const months = timeMonths(firstDay, lastDay).map((day) => formatMonth(day));
    if (months.length > 13) {
      return <div>Visualisering kan kun genereres over 13 måneder</div>;
    }

    const weeks = Array.from(new Set(
      timeDays(firstDay, lastDay)
        .map((day) =>`${getISOWeekYear(day)}-${getISOWeek(day)}`)
    ));

    const weekSelectionStartIndex = weekSelection
      ? weeks.indexOf(`${weekSelection.year}-${weekSelection.start}`)
      : undefined;

    const monthsStartWeek = months.map((month) => {
      return timeMonday.count(new Date(months[0]), new Date(month));
    });

    const widthCellsCount = timeMonday.count(firstDay, lastDay) + 1;
    const cellSize = Math.min(
      (height - margin.top - margin.bottom - 21 - 21) / 7,
      (width - margin.left - margin.right - 2) / widthCellsCount
    );
    if (cellSize < 1) {
      return <div>Ingen visualisering å vise</div>;
    }
    const calcHeight = cellSize * 7 + margin.top + margin.bottom + 21 + 21;
    const calcWidth = cellSize * widthCellsCount + margin.left + margin.right + 2;

    const dotVisScale = this.getScale(dotScale, dotColorScheme, data, true);
    const backVisScale = this.getScale(backScale, backColorScheme, data, false);

    return (
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <svg
          width={calcWidth}
          height={calcHeight}
          key={"calendar-heatmap"}
          className="calendar-heatmap"
        >
          <Group
            transform={`translate(${margin.left},${margin.top})`}
            key={`calendar-heatmap-group`}
          >
            {months.map((month, monthId) => (
              <Group
                transform={`translate(${
                  monthsStartWeek[monthId] * cellSize
                },0)`}
                key={`month-group-${month}`}
              >
                {/* Month label has to be aligned with the upper day boxes
                 *  In the most simple way, we just detect if the month starts
                 *  on Monday and adjust the offset.
                 */}
                <text
                  transform={`translate(${cellSize * (
                    moment(month, 'YYYY-MM').startOf('month').day() === 1 ? 2.5 : 3.0
                  )},${11})`}
                  fontFamily="sans-serif"
                  textAnchor="middle"
                  key={`month-label-${formatDay(month)}`}
                >
                  {typeof monthsLabels !== "undefined" ? monthsLabels[month] : month}
                </text>
                <Group
                  transform="translate(0,20)"
                  key={`month-days-group-${formatDay(month)}`}
                >
                  {range(0, daysInMonth(month)).map((dayIndex) => {
                    const day = new Date(month + "-" + (dayIndex + 1));
                    const dayString = formatDay(day);
                    let value = data.get?.(month).get?.(dayString) || [];
                    
                    let offs = cellSize / 20;
                    return  value.reduce(
                      (acc, v, i) => {
                        if (v[1]) {
                          acc.push(
                            <rect
                              fill={v[1] ? backVisScale(v[1]) : undefinedColor}
                              width={cellSize / 2 - 2 * offs}
                              height={cellSize / 2 - 2 * offs}
                              x={
                                (getMonthWeek(day) * cellSize)
                                + ((i % 2) * cellSize / 2)
                                + 2 * (1 - (i % 2)) * offs
                              }
                              y={
                                (getDayOfWeek(day) * cellSize)
                                + (Math.floor(i / 2) * cellSize / 2)
                                + 2 * (1 - Math.floor(i / 2)) * offs
                              }
                              key={`day-rect-back-${dayString}-${i}`}
                            />
                          )
                        }
                        if (v[0]) {
                          acc.push(
                            <rect
                              fill={v[0] ? dotVisScale(v[0]) : undefinedColor}
                              width={cellSize / 2 - 2 * offs}
                              height={cellSize / 2 - 2 * offs}
                              x={
                                (getMonthWeek(day) * cellSize)
                                + ((i % 2) * cellSize / 2)
                                + 2 * (1 - (i % 2)) * offs
                              }
                              y={
                                (getDayOfWeek(day) * cellSize)
                                + (Math.floor(i / 2) * cellSize / 2)
                                + 2 * (1 - Math.floor(i / 2)) * offs
                              }
                              key={`day-rect-dot-${dayString}-${i}`}
                              rx={cellSize / 5}
                            />
                          )
                        }
                        return acc;
                      },
                      [
                        <rect
                          fill={undefinedColor}
                          stroke="#aaa"
                          width={cellSize}
                          height={cellSize}
                          x={getMonthWeek(day) * cellSize}
                          y={getDayOfWeek(day) * cellSize}
                          rx={cellSize / 5}
                          key={`day-rect-${dayString}`}
                        >
                          <title>{dayString}</title>
                        </rect>
                      ]
                    )
                  })}
                </Group>
                <Group fill="none" stroke="#000" transform="translate(0,20)">
                  {
                    <path
                      d={this.getMonthPath(new Date(month), cellSize)}
                      strokeWidth={2}
                      strokeLinejoin="round"
                      key={`month-outlier-${formatDay(month)}`}
                    />
                  }
                </Group>
              </Group>
            ))}
          </Group>
          <Group
            transform={`translate(${margin.left},${
              cellSize * 7 + margin.top + 21
            })`}
            key={`calendar-heatmap-group-weeks`}
          >
            {weeks.map((week, weekId) => (
              <Group
                transform={`translate(${cellSize * weekId},0)`}
                key={`week-label-${weekId}`}
                width={cellSize}
                height={cellSize}
              >
                <rect
                  fillOpacity={0}
                  stroke="#ccc"
                  width={cellSize}
                  height={cellSize}
                  rx={0}
                  ry={0}
                  strokeDasharray={[2 * cellSize, cellSize]}
                ></rect>
                <text
                  fontFamily="sans-serif"
                  x={cellSize / 2}
                  y={cellSize / 2}
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize={(cellSize * 2) / 3}
                >
                  {week.split("-")[1]}
                </text>
              </Group>
            ))}
          </Group>
          {weekSelection && (
            <Group
              transform={`translate(${margin.left},${margin.top + 21})`}
              key={`calendar-heatmap-group-week-selection`}
            >
              <rect
                fillOpacity={0.1}
                width={weekSelectionStartIndex * cellSize}
                height={7 * cellSize}
                strokeWidth={0}
                x={0}
                y={0}
              ></rect>
              <path
                fill="none"
                stroke={weekSelection.color}
                strokeWidth={4}
                d={`
                  m ${weekSelectionStartIndex * cellSize},0
                  v ${cellSize * 7}
                  h ${cellSize * weekSelection.count}
                  v -${cellSize * 7}
                  h -${cellSize * weekSelection.count - 1}
                `}
              />
              <rect
                fillOpacity={0.1}
                width={(weeks.length - weekSelectionStartIndex - weekSelection.count) * cellSize}
                height={7 * cellSize}
                strokeWidth={0}
                x={(weekSelectionStartIndex + weekSelection.count) * cellSize}
                y={0}
              ></rect>
            </Group>
          )}
        </svg>
        {dotLegend && <HeatmapLegend
          label={dotLegendLabel}
          scaleName={dotScale.name}
          scale={dotVisScale}
          cellSize={cellSize}
          margin={{
            ...margin,
            bottom: 10
          }}
          hidden={false}
        />}
        {backLegend && <HeatmapLegend
          label={backLegendLabel}
          scaleName={backScale.name}
          scale={backVisScale}
          cellSize={cellSize}
          margin={margin}
          hidden={false}
        />}
      </div>
    );
  }
}
