import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Checkbox } from "rendition";
import { dynoapeAPI, getSortedPositions } from "../api/dynoape";
import { useParams } from "react-router-dom";
import { Table } from "rendition";
import { CirclePicker } from 'react-color';
import {AvatarCardEmployeePosition, SolidInput} from "../lib/styled-components";
import uniqolor from "uniqolor";
import { validCharactersRegex } from "../lib/common";

const PositionsTable = () => {
  const defaultPosition = { name: "", color: "000000", originalColor: "000000", priority: 0 };

  const { departmentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [showPositionModal, setShowModal] = useState({ "show": false, "id": "" });
  const [showDeleteModal, setShowDeleteModal] = useState({ "show": false, "id": "" });
  const [showInvalidNameError, setShowInvalidNameError] = useState({ "show": false, "msg": "" });
  const [showInvalidAliasError, setShowInvalidAliasError] = useState({ "show": false, "msg": "" });
  const [position, setPositionInput] = useState({ ...defaultPosition });
  const [positions, setPositions] = useState([]);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const columns = [
    {
      field: 'name',
      label: <span style={{ width: "300px", display: "block" }}>Stillingskategori</span>,
      render: function displayColumn(data, row) {
        if (!data) {
          return (<p>Mangler stilling</p>)
        }
        return (
          <AvatarCardEmployeePosition style={{ backgroundColor: row.color !== "000000" ? `#${row.color}` : uniqolor(row.id, { format: 'rgb' }).color, color: "white" }}>
            {data}
          </AvatarCardEmployeePosition>
        )
      },
    },
    {
      field: 'alias',
      label: <span style={{ width: "300px", display: "block" }}>Alias</span>,
      render: function displayColumn(data) {
        if (!data) {
          return (<p>Mangler alias</p>)
        }
        return (
            <p>{data}</p>
        )
      },
    },
    {
      field: 'id',
      label: ' ',
      render: function displayColumn(data, row) {
        return <Button primary onClick={() => {
          if (row.color !== "000000")
            setDisplayColorPicker(true);
          setPositionInput({ name: row.name, color: row.color, originalColor: row.color, priority: row.priority, alias: row.alias });
          setShowModal({ "show": true, "id": data });
        }} style={{ float: "right", width: "220px" }}>Endre</Button>;
      }
    },
    {
      field: 'id',
      label: ' ',
      render: function displayColumn(data) {
        return <Button primary onClick={() => setShowDeleteModal({ "show": true, "id": data })} style={{ float: "right", width: "220px" }}>Slett</Button>;
      }
    }
  ];

  const closeModal = () => {
    setShowInvalidNameError({ "show": false, "msg": "" });
    setPositionInput({ ...defaultPosition });
    setShowModal({ "show": false, "id": "" });
    setDisplayColorPicker(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      let pos = await getSortedPositions(departmentId);
      setPositions(pos);
      setIsLoading(false);
    }
    fetchData()
  }, []);

  const addPosition = async () => {
    if (positions.length >= 5) {
      setShowInvalidNameError({ "show": true, "msg": "Kan ikke ha flere enn 5 aktive stillinger" });
      return;
    }
    if (position.name.trim().length <= 0) {
      setShowInvalidNameError({ "show": true, "msg": "Stillingsnavn er påkrevd" });
      return;
    }
    if (showInvalidNameError.show || showInvalidAliasError.show) {
      return;
    }

    delete position.originalColor;

    await dynoapeAPI.post(`/api/v1/department/${departmentId}/position`, position);

    let pos = await getSortedPositions(departmentId);
    setPositions(pos);
    closeModal();
  };

  const editPosition = async (id) => {
    if (position.name.trim().length <= 0) {
      setShowInvalidNameError({ "show": true, "msg": "Stillingsnavn er påkrevd" });
      return;
    }
    if (showInvalidNameError.show || showInvalidAliasError.show) {
      return;
    }

    delete position.originalColor;

    await dynoapeAPI.put(`/api/v1/department/${departmentId}/position/${id}`, position);

    let pos = await getSortedPositions(departmentId);
    setPositions(pos);
    closeModal();
  };

  const deletePosition = async (id) => {
    try {
      await dynoapeAPI.delete(`/api/v1/department/${departmentId}/position/${id}`);
      setPositions(positions.filter(p => { return p.id !== id }));
    } catch (error) {
      console.error(error);
    }
    setShowDeleteModal({ show: false, id: "" });
  };

  return (
    <div>
      <Button icon={<p>+</p>} ml="auto" mb="10px" onClick={() => setShowModal({ "show": true, "id": "" })} primary>Legg til stillingskategori</Button> <span style={{ float: "right" }}>* Du kan maks legge til 5 stillinger</span>
      {isLoading ? <Spinner show /> : !positions?.length ?
        <div style={{ background: "white", padding: "10px 20px 20px 10px" }}><h4>Ingen stillinger funnet</h4></div>
        : <Table columns={columns} data={positions} rowKey="id" />
      }
      {
        showPositionModal.show && (
          <Modal
            title={showPositionModal.id !== "" ? "Endre stilling" : "Legg til ny stillingskategori"}
            cancel={() => {
              closeModal();
            }}
            cancelButtonProps={{
              children: 'Avbryt'
            }}
            done={() => {
              if (position.name.trim().length <= 0) {
                setShowInvalidNameError({ "show": true, "msg": "Et navn er påkrevd, skriv inn et navn på stillingen" });
                return;
              }
              if (showInvalidNameError.show || showInvalidAliasError.show) {
                return;
              }

              if (showPositionModal.id !== "")
                return editPosition(showPositionModal.id);

              addPosition();
            }}
            action={showPositionModal.id !== "" ? "Lagre" : "Legg til"}
          >
            <SolidInput
              onChange={(e) => {
                let name = e.target.value.trim();

                if (name.length <= 0) {
                  setShowInvalidNameError({ "show": true, "msg": "Et navn er påkrevd, skriv inn et navn på stillingen" });
                  return;
                }

                if (positions.filter(p => p.name.toLowerCase() === name.toLowerCase() && p.id !== showPositionModal.id).length > 0) {
                  setShowInvalidNameError({ "show": true, "msg": "Det finnes allerede en stilling med det navnet" });
                  return;
                }

                if (name.length > 30) {
                  setShowInvalidNameError({ "show": true, "msg": "Navn på stillingen kan ikke være lenger enn 30 tegn" });
                  return;
                }

                if (!validCharactersRegex().test(name)) {
                  setShowInvalidNameError({ "show": true, "msg": "Navn på stillingen kan ikke inneholde ugyldige tegn" });
                  return;
                }

                setShowInvalidNameError({ "show": false, "msg": "" });
                setPositionInput({ ...position, name: name });
              }}
              defaultValue={position.name}
              placeholder="Navn"
              style={{ marginBottom: "10px" }}
            />
            <SolidInput
                onChange={(e) => {
                  let alias = e.target.value.trim();

                  if (alias.length > 30) {
                    setShowInvalidAliasError({ "show": true, "msg": "Alias på stillingen kan ikke være lenger enn 30 tegn" });
                    return;
                  }

                  if (!validCharactersRegex().test(alias)) {
                    setShowInvalidNameError({ "show": true, "msg": "Alias på stillingen kan ikke inneholde ugyldige tegn" });
                    return;
                  }
                  if(alias.length === 0) alias = undefined;

                  setShowInvalidAliasError({ "show": false, "msg": "" });
                  setPositionInput({ ...position, alias: alias });
                }}
                defaultValue={position.alias}
                placeholder="Alias"
                style={{ marginBottom: "10px" }}
            />
            <Checkbox
              label="Egendefinert farge"
              checked={displayColorPicker}
              onChange={(e) => {
                if (!e.target.checked)
                  setPositionInput({ ...position, color: "000000" });
                setDisplayColorPicker(e.target.checked);
              }}
            />
            {displayColorPicker && <div style={{ marginTop: "10px" }}><CirclePicker
              width="650px"
              color={`#${position.color}`}
              colors={[
                "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5",
                "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50",
                "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800"].filter(
                  color => {
                    if (positions.some(p => p.color === color.substring(1) && p.color !== position.originalColor)) {
                      return false;
                    }
                    return true;
                  }
                )}
              onChangeComplete={(e) => {
                setPositionInput({ ...position, color: e.hex.substring(1) });
              }}
            /></div>}

            {showInvalidNameError.show && (
              <p style={{ color: "#BF3D2B" }}>{showInvalidNameError.msg}</p>
            )}
            {showInvalidAliasError.show && (
                <p style={{ color: "#BF3D2B" }}>{showInvalidNameError.msg}</p>
            )}
          </Modal>
        )
      }
      {showDeleteModal.show && (
        <Modal
          title="Slett stilling"
          cancel={() => {
            setShowDeleteModal({ show: false, id: "" });
          }}
          done={() => {
            deletePosition(showDeleteModal.id);
          }}
          action="Slett stilling"
          cancelButtonProps={{
            children: 'Avbryt'
          }}
        >
          <p>Er du sikker på at du vil slette denne stillingen?</p>
        </Modal>
      )}
    </div>
  );
};

export default PositionsTable;
