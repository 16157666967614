import React from "react";
import { AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleOrdinal } from '@visx/scale';

/**
 * Component to show basic horizontal bar chart.
 * 
 * @param {object} style - style properties to be passed to resulting svg.
 * @param {array} data - input data, in a form of array of three-element arrays.
 *      First element is the label, second element is the min value and third is
 *      max value.
 * @param {number} width - svg width.
 * @param {number} height - svg height.
 * @param {object} margin - Margin (inner padding from svg perspective).
 * @param {array} xDomain - Domain of x axis in a form of [min, max] numerical
 *      values.
 * @param {array} colRange - Range of colors.
 * @param {number} fontSize - font size used inside the visualization.
 * @param {boolean} hidden - Whether the component should be shown or not.
 */
export const HorizontalBinBarChart = ({
    style,
    data,
    width,
    height,
    margin = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    xDomain,
    colRange,
    fontSize = 9,
    hidden = false
}) => {
    if(hidden) { return <div></div> }

    if (!Array.isArray(data)) {
        return <div>Not an array data</div>
    }

    const leftAxisMargin = 20;
    const rightTextMargin = 20;

    const yScale = scaleBand()
        .domain(data.map(d => d[0]))
        .range([0, height - margin.top - margin.bottom])
        .padding(0.2);
    
    const xScale = scaleBand()
        .domain(xDomain ? xDomain : Array.from(Array(Math.max(...data.map(d => d[2]))).keys()))
        .range([0, width - margin.left - margin.right - leftAxisMargin - rightTextMargin])
        .padding(0.2)
    
    const colScale = scaleOrdinal()
        .domain(data.map(d => d[0]))
        .range(colRange ? colRange : ['#ecf4f3']);

    return <svg width={width} height={height} style={style}>
        <Group top={margin.top} left={margin.left + leftAxisMargin}>
            {data.map((d, i) => (
                <>
                    {Array.from(Array(d[2])).map((_, j) => (
                        <rect
                            key={`ds-bar-${i}-${j}`}
                            x={xScale(j)}
                            y={yScale(d[0])}
                            width={xScale.bandwidth()}
                            height={yScale.bandwidth()}
                            fill={colScale(d[0])}
                            rx={2}
                            opacity={j < d[1] ? 1 : 0.25}
                        />
                    ))}
                </>
            ))}
        </Group>
        <AxisLeft
            top={margin.top}
            left={margin.left + leftAxisMargin}
            scale={yScale}
            tickLength={3}
            tickLineProps={{
                stroke: '#333333',
                strokeWidth: 1
            }}
            tickLabelProps={{
                fill: '#333333',
                fontSize: fontSize
            }}
            hideAxisLine
        />
    </svg>
}