import React, { useState, useContext } from "react";
import DatePicker from "react-datepicker";
import { Collapsible } from 'grommet';
import {Box, Button, ButtonGroup, Checkbox, Flex, Input, Txt} from "rendition";
import Tooltip from "../../lib/tooltip";
import  CalendarHeatmap  from '../vis-components/calendar-heatmap';
import dynomiteContext from '../dynomite';
import {
    dpCategories,
    categoryFromPattern,    
    getDaysFromDatePattern,
    rollupAndReduce,
    monthsShort,
    utcOnChange
} from "../../lib/common";
import {
    DateSelection,
    DateSelectionWithCancel
} from '../date/single-date-input'
import { PaginationJumper } from "../../lib/styled-components";

/**
 * Component to edit a date pattern.
 * 
 * @param {Object} pattern - The date pattern to edit.
 * @param {function} setPattern - Function to set the date pattern.
 * @param {Object} validation - Validation object of date pattern. Contains
 *      properties 'errors' and 'warnings'.
 * @param {boolean} readOnly - Whether the component should be read only.
 */
export default ({pattern, setPattern, validation, readOnly }) => {
    const dynomite = useContext(dynomiteContext);
    const [visYear, setVisYear] = useState(new Date().getFullYear());
    const visDays = getDaysFromDatePattern(
        dynomite,
        pattern,
        `${visYear}-01-01`,
        `${visYear}-12-31`,
        false
    );
    const categoryName = dpCategories[categoryFromPattern(pattern)].name;
    const categoryColor = dpCategories[categoryFromPattern(pattern)].color;

    const updateDay = (i, date, field) => {
        if ((field !== "includeDays") && (field !== "excludeDays")) { return; }
        const newDays = [...pattern[field]];
        newDays[i] = date;
        setPattern({ ...pattern, [field]: newDays });
    }

    const removeDay = (i, field) => {
        if ((field !== "includeDays") && (field !== "excludeDays")) { return; }
        const newDays = [...pattern[field]].sort();
        newDays.splice(i, 1);
        setPattern({ ...pattern, [field]: newDays });
    }

    /**
     * Helper to add or remove a day from the date pattern specific field.
     * Fields supported are "includeDays" and "excludeDays".
     * 
     * @param {*} date - date to be added/removed from date pattern.
     * @param {string} field - either "includeDays" or "excludeDays".
     * @returns 
     */
    const toggleDay = (date, field) => {
        if ((field !== 'includeDays') && (field !== 'excludeDays')) { return; }
        if (pattern[field].some(d => d === date)) {
            setPattern({
                ...pattern,
                [field]: pattern[field].filter(d => d !== date)
            });
        } else {
            setPattern({
                ...pattern,
                [field]: [...pattern[field], date].sort()
            });
        }
    }

    const toggleShift = (shift) => {
        const newState = { ...pattern }
        if (newState.daySegments.includes(shift)) {
            newState.daySegments = newState.daySegments.filter(e => e !== shift);
        } else {
            newState.daySegments.push(shift);
        }
        setPattern(newState);
    }

    return <Box>
        {/* First line with summary, day_segment, optimize and calendar year */}
        <Flex
            justifyContent="space-between"
            style={{ gap: "1em" }}
        >
            <Box style={{
                flex: "2 1 20%",
                pointerEvents: readOnly ? "none": ""
            }}>
                <Txt style={{ "marginBottom": "1em" }}
                    data-for="pattern-summary"
                    data-tip="Navngi en tilpasning">Kort beskrivelse</Txt>
                <Tooltip id="pattern-summary" />
                <Input
                    value={pattern.summary}
                    placeholder="Legg til beskrivelse"
                    onChange={e => { pattern.summary = e.target.value; setPattern({ ...pattern }) }}
                />
            </Box>
            <Box
                style={{
                    flex: "0 0 min-content",
                    padding: "4px",
                    pointerEvents: readOnly ? "none": ""
                }}
                className={Object.hasOwn(validation.errors, "/day_segments") ? "date-pattern-error" : ""}
            >
                <Txt style={{ "marginBottom": "1em" }}
                    data-for="pattern-shifts"
                     data-tip="Velg hvilken vaktkategori tilpasningen skal omfatte for dagen(e) eller uken/perioden">Velg vakter du ønsker den ansatte <u>ikke</u> skal arbeide</Txt>
                <Tooltip id="pattern-shifts" />
                <ButtonGroup>
                    <Button
                        style={{
                            backgroundColor: pattern.daySegments.includes("D") ? categoryColor: '#fff',
                            color: pattern.daySegments.includes("D") ? '#fff': '#333'
                        }}
                        primary={pattern.daySegments.includes("D")}
                        onClick={() => toggleShift("D")}
                    >Dag</Button>
                    <Button
                        style={{
                            backgroundColor: pattern.daySegments.includes("A") ? categoryColor: '#fff',
                            color: pattern.daySegments.includes("A") ? '#fff': '#333'
                        }}
                        primary={pattern.daySegments.includes("A")}
                        onClick={() => toggleShift("A")}
                    >Kveld</Button>
                    <Button
                        style={{
                            backgroundColor: pattern.daySegments.includes("N") ? categoryColor: '#fff',
                            color: pattern.daySegments.includes("N") ? '#fff': '#333'
                        }}
                        primary={pattern.daySegments.includes("N")}
                        onClick={() => toggleShift("N")}
                    >Natt</Button>
                    <Button
                        style={{
                            backgroundColor: pattern.daySegments.includes("L") ? categoryColor: '#fff',
                            color: pattern.daySegments.includes("L") ? '#fff': '#333'
                        }}
                        primary={pattern.daySegments.includes("L")}
                        onClick={() => toggleShift("L")}
                    >Lang</Button>
                </ButtonGroup>
            </Box>
            <Box style={{ 
                flex: "0 0 100px",
                pointerEvents: readOnly ? "none": ""
            }}>
                <Txt style={{ "marginBottom": "1.5em" }}
                    data-for="pattern-optimize"
                    data-tip="Tillat avvik avvik i turnusplanen">Tillat avvik</Txt>
                <Tooltip id="pattern-optimize" />
                <Checkbox
                    toggle
                    reverse
                    checked={pattern.optimize}
                    onChange={e => { pattern.optimize = e.target.checked; setPattern({ ...pattern }) }}
                />
            </Box>
            <Box style={{ flex: "0 0 125px"}}>
                <Txt style={{ "marginBottom": "1em" }}
                    data-for="pattern-vis-year"
                    data-tip="Datoen av år hvis tilpasningen skal vises">Se år</Txt>
                <Tooltip id="pattern-vis-year" />
                <PaginationJumper
                    value={visYear}
                    onLeftChange={(value) => setVisYear(value - 1)}
                    onRightChange={(value) => setVisYear(value + 1)}
                />
            </Box>
        </Flex>
        {/* Grid with the rest of the Picker inputs  */}
        <Box style={{
            display: "grid",
            gridTemplateColumns: "1fr 4fr",
            alignItems: "center",
            gap: "1.5em",
            marginTop: "1.5em",
            pointerEvents: readOnly ? "none": ""
        }}>
            {/* ########## CalendarHeatmap section ########## */}
            <Box style={{ "gridArea": "1 / 1 / span 1 / span 2" }}>
                <CalendarHeatmap 
                    data={
                        rollupAndReduce(
                            [
                                visDays.map(day => ({
                                    Date: day,
                                    Value: [
                                        categoryName,
                                        ... pattern.daySegments.length !== 4 ? [undefined] : []
                                    ]
                                }))
                            ],
                            (day) => ({
                                Date: day[0].Date,
                                Value: day[0].Value
                            })
                        )
                    }
                    inMonths={
                        Array.from(Array(12).keys()).map(mId => 
                            `${visYear}-${mId + 1}`
                        )
                    }
                    monthsLabels={Array.from(Array(12).keys()).reduce((a, v) => ({
                            ...a,
                            [`${visYear}-` + `0${v + 1}`.slice(-2)]: monthsShort[v]
                        }),
                        {}
                    )}
                    width={1200}
                    height={200}
                    ordinalScale={{domain: [categoryName]}}
                    colorScheme={[[categoryColor]]}
                    undefinedColor = "#ffffff"
                    showLegend = {false}
                    hidden={false}
                />
            </Box>
            {/* ########## Series section ########## */}
            <Box
                style={{ "gridArea": "2 / 1 / span 1 / span 1" }}
                data-for="pattern-toggle"
                data-tip="Ved ferie/fri over flere dager eller uker kan du legge til intervaller eller mønster her"
            >
                <Checkbox
                    toggle
                    reverse
                    label="Legg til intervall/mønster"
                    checked={pattern.series.toggled}
                    onChange={e => { pattern.series.toggled = e.target.checked; setPattern({ ...pattern }) }}
                />
            </Box>
            <Tooltip id="pattern-toggle" />
            <Flex
                justifyContent="start"
                style={{
                    gridArea: "2 / 2 / span 1 / span 1",
                    gap: "1em",
                    pointerEvents: readOnly ? "none": "",
                    padding: "4px",
                }}
                className={[
                    ... !pattern.series.toggled ? ["disabled-row"] : [],
                    ... Object.keys(validation.warnings).some(e => ~e.indexOf("/series")) ? ["date-pattern-warning"] : [],
                ].join(", ")}
            >
                <Flex
                    justifyContent="start"
                    style={{
                        gap: "1em",
                        padding: ".5em",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        cursor: !pattern.series.toggled ? "not-allowed" : "pointer",
                        width: "155px"
                    }}
                    data-for="pattern-from"
                    data-tip="Klikk på kalender ikonet for å sette startdato for periode med fri"
                >
                    <Txt style={{paddingTop: "3px"}}>Fra</Txt>
                    <DateSelection
                        filterDate={(date) => date.getDay() === 1}
                        value={pattern.series.from}
                        disabled={!pattern.series.toggled}
                        maxDate={pattern.series.until && new Date(pattern.series.until)}
                        onChange={date => { pattern.series.from = date; setPattern({ ...pattern })}}
                    />
                </Flex>
                <Tooltip id="pattern-from" />
                <Flex
                    justifyContent="start"
                    style={{
                        gap: "1em",
                        padding: ".5em",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        cursor: !pattern.series.toggled ? "not-allowed" : "pointer",
                        width: "155px"
                    }}
                    data-for="pattern-until"
                    data-tip="Klikk på kalender ikonet for å sette sluttdato for periode med fri"
                >
                    <Txt style={{ paddingTop: "3px" }}>Til</Txt>
                    <DateSelection
                        value={pattern.series.until}
                        minDate={new Date(pattern.series.from)}
                        disabled={!pattern.series.toggled}
                        onChange={date => { pattern.series.until = date; setPattern({ ...pattern }) }}
                    />
                </Flex>
                <Tooltip id="pattern-until" />
                <Flex
                    justifyContent="start"
                    style={{
                        "gap": "0.75em",
                        "border": "1px solid #ccc",
                        "borderRadius": "5px"
                    }}
                    data-for="pattern-repeat"
                    data-tip="Dersom perioden med fri skal gjentas i andre uker velger du hvor ofte tilpasningen skal gjennomføres. 1= hver uke, 2= annenhver uke etc"
                >
                    <Txt style={{ padding: "0.75em 0.5em" }}>Hver</Txt>
                    <Flex style={{
                        gap: "0.5em",
                        alignItems: "center"
                    }}>
                        <span style={{ width: "5em" }}>
                            <Input
                                type="number"
                                min="1"
                                defaultValue="1"
                                value={pattern.series.nth}
                                disabled={!pattern.series.toggled}
                                onChange={e => {
                                    let val = parseInt(e.target.value ? e.target.value : 1);
                                    pattern.series.nth = isNaN(val) ? 1 : val;
                                    setPattern({ ...pattern })
                                }}
                            />
                        </span>
                    </Flex>
                </Flex>
                <Tooltip id="pattern-repeat" />
                <Box style={{paddingTop: "3px"}}>
                    <ButtonGroup>
                        <Button
                            style={{
                                padding: "4px 25px",
                                backgroundColor: pattern.series.mask[0] ? categoryColor: '#fff',
                                color: pattern.series.mask[0] ? '#fff': '#333',
                            }}
                            primary={pattern.series.mask[0]}
                            disabled={!pattern.series.toggled}
                            onClick={() => { pattern.series.mask[0] = !pattern.series.mask[0]; setPattern({ ...pattern }) }}
                        >Man</Button>
                        <Button
                            style={{
                                padding: "4px 25px",
                                backgroundColor: pattern.series.mask[1] ? categoryColor: '#fff',
                                color: pattern.series.mask[1] ? '#fff': '#333',
                            }}
                            primary={pattern.series.mask[1]}
                            disabled={!pattern.series.toggled}
                            onClick={() => { pattern.series.mask[1] = !pattern.series.mask[1]; setPattern({ ...pattern }) }}
                        >Tir</Button>
                        <Button
                            style={{
                                padding: "4px 25px",
                                backgroundColor: pattern.series.mask[2] ? categoryColor: '#fff',
                                color: pattern.series.mask[2] ? '#fff': '#333',
                            }}
                            primary={pattern.series.mask[2]}
                            disabled={!pattern.series.toggled}
                            onClick={() => { pattern.series.mask[2] = !pattern.series.mask[2]; setPattern({ ...pattern }) }}
                        >Ons</Button>
                        <Button
                            style={{
                                padding: "4px 25px",
                                backgroundColor: pattern.series.mask[3] ? categoryColor: '#fff',
                                color: pattern.series.mask[3] ? '#fff': '#333',
                            }}
                            primary={pattern.series.mask[3]}
                            disabled={!pattern.series.toggled}
                            onClick={() => { pattern.series.mask[3] = !pattern.series.mask[3]; setPattern({ ...pattern }) }}
                        >Tor</Button>
                        <Button
                            style={{
                                padding: "4px 25px",
                                backgroundColor: pattern.series.mask[4] ? categoryColor: '#fff',
                                color: pattern.series.mask[4] ? '#fff': '#333',
                            }}
                            primary={pattern.series.mask[4]}
                            disabled={!pattern.series.toggled}
                            onClick={() => { pattern.series.mask[4] = !pattern.series.mask[4]; setPattern({ ...pattern }) }}
                        >Fre</Button>
                        <Button
                            style={{
                                padding: "4px 25px",
                                backgroundColor: pattern.series.mask[5] ? categoryColor: '#fff',
                                color: pattern.series.mask[5] ? '#fff': '#333',
                            }}
                            primary={pattern.series.mask[5]}
                            disabled={!pattern.series.toggled}
                            onClick={() => { pattern.series.mask[5] = !pattern.series.mask[5]; setPattern({ ...pattern }) }}
                        >Lør</Button>
                        <Button
                            style={{
                                padding: "4px 25px",
                                backgroundColor: pattern.series.mask[6] ? categoryColor: '#fff',
                                color: pattern.series.mask[6] ? '#fff': '#333',
                            }}
                            primary={pattern.series.mask[6]}
                            disabled={!pattern.series.toggled}
                            onClick={() => { pattern.series.mask[6] = !pattern.series.mask[6]; setPattern({ ...pattern }) }}
                        >Søn</Button>
                    </ButtonGroup>
                </Box>
            </Flex>
            {/* ########## Include Days section ########## */}
            <Box
                style={{
                    gap: "1em",
                    gridArea: "3 / 1 / span 1 / span 1",
                    alignSelf: "start",
                    pointerEvents: readOnly ? "none": ""
                }}
            >
                <DatePicker
                    showWeekNumbers
                    locale="nb"
                    dateFormat="dd.MM.yyyy"
                    onChange={utcOnChange((date) => toggleDay(date, "includeDays"))}
                    showPopperArrow={true}
                    popperPlacement="right"
                    portalId="root-portal"
                    shouldCloseOnSelect={false}
                    selected={pattern.includeDays.length > 0 ? new Date(pattern.includeDays.slice(-1)) : undefined}
                    highlightDates={pattern.includeDays.map(d => new Date(d))}
                    customInput={
                        <Button
                            style={{width: "100%"}}
                            primary
                            data-for="pattern-include-day"
                            data-tip="Velg dato for tilpasning"
                        >Legg til dag</Button>
                    }
                />
                <Tooltip id="pattern-include-day" />
            </Box>
            <Box 
                style={{
                    gridArea: "3 / 2 / span 1 / span 1",
                    pointerEvents: readOnly ? "none": ""
                }}
            >
                <Collapsible open={pattern.includeDays.length > 0}>
                    <Flex
                        flexWrap="wrap"
                        style={{
                            gap: "1em",
                            alignItems: "center",
                            pointerEvents: readOnly ? "none": "" 
                        }}
                    >
                        {
                            [...pattern.includeDays].sort()
                                .map((d, i) => <DateSelectionWithCancel
                                    key={i}
                                    value={d}
                                    onChange={(date) => updateDay(i, date, "includeDays")}
                                    onClose={() => removeDay(i, "includeDays")}
                                    backgroundCol={pattern.excludeDays.indexOf(d) >= 0 ? '#ececec' : 'white'}
                                    excludeDates={visDays.concat(pattern.excludeDays).map(d => new Date(d))}
                                />)
                        }
                    </Flex>
                </Collapsible>
            </Box>
            {/* ########## Exclude Days section ########## */}
            <Box
                style={{
                    gap: "1em",
                    gridArea: "4 / 1 / span 1 / span 1",
                    alignSelf: "start",
                    pointerEvents: readOnly ? "none": ""
                }}
            >
                <DatePicker
                    showWeekNumbers
                    locale="nb"
                    dateFormat="dd.MM.yyyy"
                    onChange={utcOnChange((date) => toggleDay(date, "excludeDays"))}
                    showPopperArrow={true}
                    popperPlacement="right"
                    portalId="root-portal"
                    shouldCloseOnSelect={false}
                    selected={pattern.excludeDays.length > 0 ? new Date(pattern.excludeDays.slice(-1)) : undefined}
                    highlightDates={pattern.excludeDays.map(d => new Date(d))}
                    includeDates={visDays.map(d => new Date(d))}
                    customInput={
                        <Button
                            style={{width: "100%"}}
                            primary
                            data-for="pattern-exclude-day"
                            data-tip="Velg dato for ekskluder fra tilpasning"
                        >Ekskluder dag</Button>
                    }
                />
                <Tooltip id="pattern-exclude-day" />
            </Box>
            <Box 
                style={{
                    gridArea: "4 / 2 / span 1 / span 1",
                    pointerEvents: readOnly ? "none": ""
                }}
            >
                <Collapsible open={pattern.excludeDays.length > 0}>
                    <Flex
                        flexWrap="wrap"
                        style={{
                            gap: "1em",
                            alignItems: "center",
                            pointerEvents: readOnly ? "none": "" 
                        }}
                    >
                        {
                            [...pattern.excludeDays].sort()
                                .map((d, i) => <DateSelectionWithCancel
                                    key={i}
                                    value={d}
                                    onChange={(date) => updateDay(i, date, "excludeDays")}
                                    onClose={() => removeDay(i, "excludeDays")}
                                    includeDates={visDays.map(d => new Date(d))}
                                />)
                        }
                    </Flex>
                </Collapsible>
            </Box>
        </Box>
    </Box>
}
