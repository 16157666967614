import React, { useEffect, useState } from "react";
import {EmployeeCard, Header} from "../components";
import {BackButton} from "../components/buttons";
import { dynoapeAPI } from "../api/dynoape";
import { useParams } from "react-router-dom";

const Employees = () => {
    const { departmentId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [employeeNames, setEmployeeNames] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await dynoapeAPI.get(`/api/v1/department/${departmentId}/employees`);

            const emplNames = response.map(function (empl) {
                return empl.name;
            })
            setEmployeeNames(emplNames);
            setIsLoading(false);
        }
        fetchData();
    }, []);
    if (isLoading) {
        return (
            <p>Loading...</p>
        )
    }
    const defaultEmployee = {
        name: "Ny ansatt " + (employeeNames.length + 1),
        vacancyRate: 100,
        enabled: true,
        shiftMinSequence: 0,
        shiftMaxSequence: 5,
        maxShifts: 0,
        dayShiftMinSequence: 0,
        dayShiftMaxSequence: 0,
        maxDayShifts: null,
        dayShiftsMaxPerWeek: null,
        eveningShiftMinSequence: 0,
        eveningShiftMaxSequence: 0,
        maxEveningShifts: null,
        eveningShiftsMaxPerWeek: null,
        nightShiftMinSequence: 0,
        nightShiftMaxSequence: 0,
        maxNightShifts: null,
        nightShiftsMaxPerWeek: null,
        maxNightShiftWeekends: null,
        longShiftMinSequence: 0,
        longShiftMaxSequence: 0,
        maxLongShifts: null,
        longShiftsMaxPerWeek: null,
        worksOnlyWeekend: false,
        nrOfQuickReturnsPerWeekOnWeekend: 1,
        nrOfQuickReturnsPerWeekOnWeekdays: 1,
        redDaysMaxSequence: 5,
        movableHolidaysMaxPerTurnus: 5,
        comment: "",
        weekendShiftMaxSequence: -1,
        priority: employeeNames.length + 1,
        traitNames: {},
        restShiftTypes: [],
        sundaysMaxSequence: 2
    }
    return (
        <div>
            <Header title={"Legg til ny ansatt"} showLicenseEmployeeInfo={true}>
                <BackButton />
            </Header>
            <div className="content">
                <EmployeeCard initialEmployee={defaultEmployee} existingEmployeeNames={employeeNames} isNew={true}/>
            </div>
        </div>
    );
};

export default Employees;
