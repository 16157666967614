import React, {useContext, useState, useEffect, useCallback} from "react";
import { createPatch } from "rfc6902";
import {Alert, Flex, Divider, Txt, Spinner, Checkbox} from "rendition";
import ErrorPanel from "./sticky-error-panel";
import { dynoapeAPI } from "../api/dynoape";
import dynomiteContext from "./dynomite";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Tabs, Tab, notifications, Modal } from "rendition";
import TaskSettings from "./task-settings";
import TaskWeek from "./task-week";
import TaskWeekCustom from "./task-week-custom";
import TaskWeekendVisualization from "./task-weekend-visualization";
import { TaskEmployees } from "./task-employees";
import {
  debounce, dynomiteErrorPanel,
  isTaskStatusReadOnly,
  TaskCardSpinner,
  TaskCardWaitingSpinner,
  taskStuck
} from "../lib/common";
import DayCopy from "./day-copy";
import CustomDayOrWeekCopy from "./custom-day-or-week-copy";
import Tooltip from "../lib/tooltip";
import styled from "styled-components";
import sanitizeHtml from 'sanitize-html';
import {faClose} from "@fortawesome/free-solid-svg-icons/faClose";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {defaultWeeklyCoverDemands} from "../lib/defaults";

//custom data should be data with the custom patches applied to it
//should also pass down ids of input fields that have custom data
//find a way to solve these issues
//send in custom task in tab?
const Task = (params) => {
  const { register, unregister, handleSubmit, trigger, setValue, control, formState: { errors }, watch } = useForm({ defaultValues: params.task, mode: 'onBlur' });
  const [notification, setNotification] = useState("");
  const [task, setTask] = useState(params.task);
  const [taskStatus, setTaskStatus] = [params.taskStatus, params.setTaskStatus];
  const [inputData, setInputData] = useState({});
  const { departmentId, taskId } = useParams();
  const [showConfirmGenerateModal, setShowConfirmGenerateModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [positions] = useState(params.positions);
  const [traitNames] = useState(params.traitNames);
  const [employees] = useState(params.employees);
  const [isSaving, setIsSaving] = useState(false);
  const dynomite = useContext(dynomiteContext);
  const data = watch();
  const history = useHistory();
  const [showValidation, setShowValidation] = useState(false);
  const [nrOfErrorPanelsOpen, setNrOfErrorPanelsOpen] = useState(0);
  const [updateTaskOngoing, setUpdateTaskOngoing] = useState(false);
  const [switchToDaySegmentView, setSwitchToDaySegmentView] = useState(localStorage.getItem(`department-${departmentId}-daySegmentViewEnabled`) === 'true');

  /**
   * First tab is Settings. If you press copy days while you are on that tab, default copy day should be Monday.
   * Therefore 'MONDAY' is both first and second element
   * @type {string[]}
   */
  const tabDays = ['MONDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

  const days = {
    "MONDAY": "Mandag",
    "TUESDAY": "Tirsdag",
    "WEDNESDAY": "Onsdag",
    "THURSDAY": "Torsdag",
    "FRIDAY": "Fredag",
    "SATURDAY": "Lørdag",
    "SUNDAY": "Søndag",
    "HOLIDAYS": "Helligdager"
  };

  const daysForCustomBp = Object.fromEntries(Object.entries(days).filter(([key]) => key !== "HOLIDAYS"));
  const shifts = {
    "D": "Dagvakt",
    "A": "Kveldsvakt",
    "N": "Nattevakt",
    "L": "Langvakt/Mellomvakt"
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (taskStatus === "PROCESSING" || taskStatus === "QUEUED" || taskStatus === "STARTING") {
        getTaskStatus();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [taskStatus]);

  useEffect(() => {
    if (notification == "") return;
    notifications.removeNotification(notification.id - 1);

    notifications.addNotification({
      id: notification.id,
      content: notification.msg,
      duration: 2000,
      container: "top-center",
      type: notification.type
    });
  }, [notification]);

  const [configErrors, setConfigErrors] = useState([]);
  const [configWarnings, setConfigWarnings] = useState([]);
  const [nrOfConfigWarnings, setNrOfConfigWarnings] = useState(0);
  const [dynomiteInternalError, setDynomiteInternalError] = useState(undefined);
  const validate = () => {
    let allGood = true;
    if (!isTaskStatusReadOnly(taskStatus)) {
      try {
        let _data = data;
        Object.keys(defaultWeeklyCoverDemands).forEach(d => {
          if(!Object.keys(_data.config.weeklyCoverDemands).includes(d)) {
            _data.config.weeklyCoverDemands[d] = {};
          }
        })
        _data.config.dailyCoverPatches = task.config.dailyCoverPatches;
        let js = JSON.stringify({
          employees: employees.filter(e => e.enabled),
          positions: Object.fromEntries(positions.map(p => [p.id, p.name])),
          traits: traitNames,
          holidays: Object.keys(params.department.countryRules.holidays),
          dailyCoverPatches: task.config.dailyCoverPatches,
          ..._data.config
        }, null, 4);
        dynomite.dynomite.parseConfig(js);
        setConfigErrors([]);
        setConfigWarnings([]);
        setNrOfConfigWarnings(0);
        setDynomiteInternalError(undefined);
      } catch (e) {
        if (e.payload) {
          console.error("dynomite found errors", e.payload);
          const errs = e.payload.filter(e => !e.tag.includes('Warning::'));
          if(errs.length > 0) {
            allGood = false;
            setConfigErrors(errs.splice(0, 100));
          } else {
            setConfigErrors([])
          }
          const warnings =  e.payload.filter(e => e.tag.includes('Warning::'));
          setNrOfConfigWarnings(warnings.length)
          if(warnings.length > 0) {
            setConfigWarnings(warnings.splice(0, 100));
          } else {
            setConfigWarnings([])
          }
          const internal = errs.some(e => e.tag === "Internal" || e.tag === "Syntax");
          allGood = allGood ? !internal : allGood ;
          setDynomiteInternalError(internal);
        } else {
          console.error("dynomite failed", e);
          allGood = false;
          setConfigErrors([]);
          setConfigWarnings([]);
          setNrOfConfigWarnings(0);
          setDynomiteInternalError(true);
        }
      }
    }
    setShowValidation(true);
    return allGood;
  }

  const generateNotificationId = function () {
    return notification == ""
      ? Math.floor(Math.random() * 90000) + 10000
      : notification.id + 1;
  }

  const submit = async (providedData) => {
    setUpdateTaskOngoing(true);
    await save(providedData);
    setUpdateTaskOngoing(false)
  };

  const save = async (providedData) => {
    if(isSaving) return;
    const data_ = providedData ? providedData : data;
    if (Object.keys(errors).length === 0) {
      const taskPatch = createPatch(task, data_).filter(operation => {
        return ((operation.op !== "remove" || operation.path.includes("employees"))
            && !operation.path.includes("/config/employees") && !operation.path.includes("/config/holidays")
            && !operation.path.includes("/config/shiftTypes") && !operation.path.includes("/config/traits")
            && !operation.path.includes("/config/positions")
            && operation.path !== "/name" && !operation.path.includes("dailyCoverPatches")  && !operation.path.includes("secondsLimit")
            && !operation.path.includes("freeMinBetweenTime") && !operation.path.includes("/config/name"))
      });
      if (taskPatch.length > 0) {
        await patchTask(taskPatch, "Bemanningsplan lagret");
      }
    }
  }

  const doPatch = async (patch, msg) => {
    setIsSaving(true);
    setUpdateTaskOngoing(true)
    const response = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}?includeTaskHolidays=true`, patch);
    if (response) {
      setTask(response);
      setTaskStatus(response.status);
      setNotification({ "id": generateNotificationId(), "msg": msg, "type": "success" });
    }
    setIsSaving(false);
    setUpdateTaskOngoing(false);
  }

  const debouncedSave = useCallback(
    debounce(save, 3000),
    [],
  );

  useEffect(() => {
    if (data.config.nrOfWeeks > 52)
      setValue(`config.nrOfWeeks`, 52);
    if (data && Object.keys(errors).length === 0 && !isTaskStatusReadOnly(taskStatus) && JSON.stringify(data) !== JSON.stringify(inputData)) {
      setInputData(JSON.parse(JSON.stringify(data)));
      debouncedSave(data, task, errors);
    }
  }, [data, debouncedSave]);

  const patchTask = async (taskPatch, message) => {
    const ok = await trigger();
    if (!ok || isSaving)
      return
    setIsSaving(true);
    const response = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}?includeTaskHolidays=true`, taskPatch);

    if (response) {
      setTask(response);
      setTaskStatus(response.status);
      setNotification({ "id": generateNotificationId(), "msg": message, "type": "success" });
    }
    setIsSaving(false);
  };

  const getTaskStatus = async () => {
    const response = await dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${taskId}`);
    setTaskStatus(response.status);
  }

  const confirmGenerate = async () => {
    await save();
    if(await validate()) {
      setShowConfirmGenerateModal(true)
    }
  }

  const generateButton =
      <Button
          success
          disabled={(configErrors.length > 0 || dynomiteInternalError) && showValidation}
          mr="5px"
          ml="5px"
          onClick={() => confirmGenerate()}
          data-for="generate"
          data-tip="Når du er ferdig å fylle ut Bemanningsplanen for alle vakter, alle dager, kan du starte generering av turnus i Dynamon"
      ><Tooltip id="generate" />Generer</Button>

  const saveButton =
      <Button className={"BpButton"}
              primary
              type="submit"
              mr="5px"
              ml="5px"
              data-for="save"
              data-tip="I Bemanningsplanen er det autolagring, men du kan gjerne lagre med denne knappen hvis du vil"
      ><Tooltip id="save" />Lagre</Button>

  const validateButton =
      <Button
          warning
          disabled={isTaskStatusReadOnly(taskStatus)}
          mr="5px"
          ml="5px"
          onClick={() => {validate();}}
          data-for="validate"
          data-tip="Trykke på denne hvis du vil validere/kontrollere bemanningsplanen"
      ><Tooltip id="validate" />Kontroller</Button>

  const changeShiftViewButton =
      <div data-for={"changeShiftViewButton"} data-tip={"Ved å velge denne, vil vaktkodene deles inn i deres respektive vaktkategorier."}>
        <Checkbox
            label={"Vaktkategori-visning"}
            mt={"10px"}
            toggle
            reverse
            checked={switchToDaySegmentView}
            onChange={() => {
              const val = !switchToDaySegmentView;
              setSwitchToDaySegmentView(val)
              localStorage.setItem(`department-${task.departmentId}-daySegmentViewEnabled`, val)
            }}
        />
        <Tooltip id={"changeShiftViewButton"}/>
      </div>

  const scheduleRequirements = dynomite.dynomite.scheduleRequirements(
    JSON.stringify(task.config)
  );

  return (
    <BpPage taskIsRunning={isTaskStatusReadOnly(taskStatus)}>

      <Tabs onActive={(e) => setSelectedDay(tabDays[e])}>
        <Tab title="Innstillinger">
          <form onSubmit={handleSubmit(submit)} onBlur={() => { trigger(); }}>
            <div style={{ top: "1px", position: "sticky", zIndex: "1", display: "flex", flexDirection: "row-reverse", height: "65px"}}>
              {(!isTaskStatusReadOnly(taskStatus) && taskStatus !== "ERROR_GENERIC") && generateButton}
              {!isTaskStatusReadOnly(taskStatus) && validateButton}
              {!isTaskStatusReadOnly(taskStatus) && saveButton}
            </div>
            <TaskSettings register={register} task={task} control={control} errors={errors} watch={watch}
                          shifts={shifts} setValue={setValue}/>
          </form>
        </Tab>
        <Tab title="Ordinær Bemanning">
          <form onSubmit={handleSubmit(submit)} onBlur={() => { trigger(); }}>
            <div style={{ top: "10px", position: "sticky", zIndex: "1", display: "flex", flexDirection: "row-reverse" }}>
              {taskStatus === "QUEUED" && <TaskCardWaitingSpinner style={{ right: "300px", position: "absolute" }} label="Venter på at generering skal starte"></TaskCardWaitingSpinner>}
              {taskStatus === "STARTING" && <TaskCardWaitingSpinner style={{ right: "400px", position: "absolute" }} label="Starter generering"></TaskCardWaitingSpinner>}
              {taskStatus === "PROCESSING" && !taskStuck(task) && <TaskCardSpinner style={{ right: "400px", position: "absolute" }} label="Generering pågår"></TaskCardSpinner>}
              {(!isTaskStatusReadOnly(taskStatus) && taskStatus !== "ERROR_GENERIC") && generateButton}
              {!isTaskStatusReadOnly(taskStatus) && validateButton}
              {!isTaskStatusReadOnly(taskStatus) && saveButton}
              <DayCopy task={task} unregister={unregister} setTask={setTask} setValue={setValue} errors={errors} save={save} selectedDay={selectedDay} taskStatus={taskStatus} />
            </div>
            <TaskWeek days={days}
                      selectedDay={selectedDay}
                      setSelectedDay={setSelectedDay}
                      register={register}
                      setValue={setValue}
                      unregister={unregister}
                      task={task}
                      errors={errors}
                      positions={positions}
                      traitNames={traitNames}
                      watch={watch}
                      patchTask={patchTask}
                      doPatch={doPatch}
                      employees={employees}
                      department={params.department}
                      switchToDaySegmentView={switchToDaySegmentView}
                      changeShiftViewButton={changeShiftViewButton}
            />
          </form>
        </Tab>
        <Tab title="Tilpasset Bemanning">
          <div>
            <div style={{ top: "10px", position: "sticky", zIndex: "1", display: "flex", flexDirection: "row-reverse" }}>
              <CustomDayOrWeekCopy errors={errors} department={params.department} taskStatus={taskStatus}
                                   existingDailyCoverPatches={task.config.dailyCoverPatches} taskStartDate={task.config.startDate} taskWeeks={task.config.nrOfWeeks}
                                   task={task} setTask={setTask} setUpdateTaskOngoing={setUpdateTaskOngoing}
              />
            </div>
            <TaskWeekCustom doPatch={doPatch} days={daysForCustomBp} department={params.department}
                            task={task} setTask={setTask}  positions={positions} traitNames={traitNames}
                            setUpdateTaskOngoing={setUpdateTaskOngoing} employees={employees}
                            switchToDaySegmentView={switchToDaySegmentView}
            validateButton={validateButton}/>
          </div>
        </Tab>
        <Tab title="Ansatte og tilgjengelighet">
          <TaskEmployees 
             task={task}
             result={params.result}
             employees={employees}
             positions={positions}
             requirements={scheduleRequirements}
          />
        </Tab>
        <Tab title="Visualisering av helg">
          <TaskWeekendVisualization departmentId={departmentId} taskId={task.id}/>
        </Tab>
        {updateTaskOngoing && <div style={{marginLeft: "20px"}}><Spinner label="Oppdatering pågår" show /></div>}
      </Tabs>


      {showConfirmGenerateModal && (
        <Modal
          cancelButtonProps={{
            children: 'Avbryt'
          }}
          done={async () => {
            let patch = [{ "op": "replace", "path": "/status", "value": "QUEUED" }]
            await patchTask(patch);
            setShowConfirmGenerateModal(false);
            history.push(`/avdelinger/${departmentId}/bemanningsplaner`);
          }}
          cancel={() => {
            setShowConfirmGenerateModal(false);
          }}
          action="Start generering"
        >
          <p style={{ fontSize: "20px" }}>Er du sikker på at du vil starte generering av denne bemanningsplanen? </p>
        </Modal>
      )}
      {showValidation &&
          <FontAwesomeIcon
              color='#ccc'
              icon={faClose}
              onClick={() => setShowValidation(false)}
              style={{
                position: "sticky",
                width: "50px",
                height: "50px",
                float: "right",
                bottom: "80px",
                cursor: "pointer",
                zIndex: "10000",
                display: nrOfErrorPanelsOpen > 0 ? "none" : "block"
              }} />
      }
      {showValidation && dynomiteErrorPanel(dynomite, configErrors, dynomiteInternalError, nrOfErrorPanelsOpen, setNrOfErrorPanelsOpen)}
      {showValidation && <ErrorPanel
          nrOfErrorPanelsOpen={nrOfErrorPanelsOpen}
          setNrOfErrorPanelsOpen={setNrOfErrorPanelsOpen}
          okLabel={<Alert success plaintext>Det er ingen advarsler</Alert>}
          errLabel={<Flex>
            <Alert warning plaintext/>
            {configWarnings &&
                <Txt bold>
                  {nrOfConfigWarnings} {nrOfConfigWarnings > 100 ? "Advarsler (viser 100)"  : (nrOfConfigWarnings > 1 ? "Advarsler" : "Advarsel")}
                  &nbsp;i Bemanningsplan
                </Txt>
            }
          </Flex>}
          isWarning={configWarnings.length > 0}
          isError={configWarnings.length > 0}
      >
        {configWarnings &&
            <div style={{ overflow: "scroll", height: "250px"}}>
              <div style={{ margin: "32px 44px" }}>
                {
                  configWarnings.map((err, i) => <>
                    {i != 0 && <Divider />}
                    <Alert plaintext>
                      <div
                          key={err}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                dynomite.dynomite.formatError(err)
                            )
                          }}
                      />
                    </Alert>
                  </>)
                }
              </div>
            </div>
        }
      </ErrorPanel>}
    </BpPage>
  )
};

const BpPage = styled.div`
  .NumberInput, .StyledInputMask, .BpSelect, .DateButton, .SolidInput, .TextArea {
    background-color: ${props => props.taskIsRunning ? 'lightgrey' : 'white'}; 
  }
  .NumberInput, .StyledInputMask, .BpSelect, .DateButton, .BpButton, .SolidInput, .TextArea {
    pointer-events: ${props => props.taskIsRunning ? 'none' : 'auto'}
  }
  .deletePatchButton {
    display: ${props => props.taskIsRunning ? 'none' : 'auto'}
  }
`;

export default Task;
