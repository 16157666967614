import React from "react";
import { format } from "@visx/vendor/d3-format";
import {
  LegendOrdinal,
  LegendQuantile,
  LegendLinear,
  LegendItem,
  LegendLabel,
  LegendThreshold
} from "@visx/legend";


/**
 * Component to show legend for a visualization scale.
 * 
 * @param {String} label - label to be used in legend.
 * @param {String} scaleName - Name of scale type, could be 'ordinal',
 *      'quantile', 'threshold' or 'linear'.
 * @param {function} scale - Function of the scale.
 * @param {number} cellSize - Size of the cell.
 * @param {object} margin - Margin for the legend.
 * @param {boolean} hidden - Whether the component should be shown or not.
 */
export default ({
    label,
    scaleName,
    scale,
    cellSize,
    margin = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    hidden = false
}) => {

    /**
     * Helper function to translate automatic threshold lables into Norwegian ones.
     * 
     * Turns:
     *  - "More than" => "Mer enn"
     *  - "Less than" => "Mindre enn"
     *  - "x to y" => "x til y"
     * 
     * @param {Object} label 
     * @returns {string}
     * */
    const translateTreshold = (label) => {
        if (typeof(label.extent[0]) === 'undefined') {
            return `Mindre enn ${label.extent[1]}`
        } else if (typeof(label.extent[1]) === 'undefined') {
            return `Mer enn ${label.extent[0]}`
        }
        return `${label.extent[0]} til ${label.extent[1]}`
    }

    return hidden ? <div></div> : (
        <div
            className='legend'
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: Object.hasOwn(margin, 'bottom') ? margin.bottom : 0,
                justifyContent: 'center',
                alignItems: 'center'
            }}
            >
            <div className='title'>{label}</div>
            {scaleName === 'ordinal' ? (
                <LegendOrdinal
                    scale={scale}
                    labelFormat={(d) => {
                        return typeof d === "number" ? format('.0f')(d) : d;
                    }}
                >
                    {(labels) => labels.map((label, i) => (
                        <LegendItem key={`legend-${i}`}>
                            <svg
                                width={cellSize}
                                height={cellSize}
                                style={{ margin: "2px 0px 0px 15px" }}
                            >
                                <rect
                                    fill={label.value}
                                    stroke="#ccc"
                                    width={cellSize}
                                    height={cellSize}
                                    rx={cellSize / 5}
                                />
                            </svg>
                            <LegendLabel margin="0 4px">{label.text}</LegendLabel>
                        </LegendItem>
                    ))}
                </LegendOrdinal>
            ) : scaleName === 'quantile' ? (
                <LegendQuantile scale={scale}>
                    {(labels) => labels.reduce(
                        (acc, label) => {
                            // Two stage processing:
                            // - first we round the extend limits to the closest
                            //   integer which is ceil for bottom and floor for
                            //   top limits
                            let ceilBottom = Math.ceil(label.extent[0]);
                            let floorTop = Math.floor(label.extent[1]);
                            let ext = [
                                ceilBottom,
                                ... (ceilBottom < floorTop) ? [floorTop] : []
                            ]
                            // - second, we make sure, that the top limit does
                            //   not overlap with the previous bottom limit
                            if (acc.length > 0) {
                                let prev = acc[acc.length - 1].value;
                                if (prev[prev.length - 1] === ceilBottom) {
                                    acc[acc.length - 1].value[prev.length - 1] -= 1
                                }
                            }
                            acc.push({
                                color: label.value,
                                value: ext
                            })
                            return acc
                        },
                        []
                    ).map((label, i) => (
                        <LegendItem key={`legend-${i}`}>
                            <svg
                                width={cellSize + 1}
                                height={cellSize + 1}
                                style={{ margin: '2px 0px 0px 15px' }}
                            >
                                <rect
                                    fill={label.color}
                                    stroke='#ccc'
                                    width={cellSize}
                                    height={cellSize}
                                    rx={cellSize / 5}
                                />
                            </svg>
                            <LegendLabel margin='0 4px'>
                                {label.value.length === 1
                                    ? `${label.value[0]}`
                                    : `${label.value[0]} - ${label.value[1]}`
                                }
                            </LegendLabel>
                        </LegendItem>
                    ))}
                </LegendQuantile>
            ) : scaleName === 'threshold' ? (
                <LegendThreshold scale={scale}>
                    {(labels) => labels.map((label, i) => (
                        <LegendItem key={`threshold-legend-${i}`}>
                            <svg
                                width={cellSize}
                                height={cellSize}
                                style={{ margin: '2px 0px 0px 15px' }}
                            >
                                <rect
                                    fill={label.value}
                                    stroke='#ccc'
                                    width={cellSize}
                                    height={cellSize}
                                    rx={cellSize / 5}
                                />
                            </svg>
                            <LegendLabel margin='0 4px'>
                                {translateTreshold(label)}
                            </LegendLabel>
                        </LegendItem>
                    ))}
                </LegendThreshold>
            ): (
                <LegendLinear
                    scale={scale}
                    labelFormat={(d) => typeof d === 'number' ? format('.0f')(d) : d}
                >
                    {(labels) => labels.map((label, i) => (
                        <LegendItem key={`legend-${i}`}>
                            <svg
                                width={cellSize}
                                height={cellSize}
                                style={{ margin: '2px 0px 0px 15px' }}
                            >
                                <rect
                                    fill={label.value}
                                    stroke='#ccc'
                                    width={cellSize}
                                    height={cellSize}
                                    rx={cellSize / 5}
                                />
                            </svg>
                            <LegendLabel margin='0 4px'>{label.text}</LegendLabel>
                        </LegendItem>
                    ))}
                </LegendLinear>
            )}
        </div>
    )
}
